import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import sortBy from 'lodash/sortBy';
import { gridSettingsApi } from '../services/ApiService';
import { IGN_API, POST } from '../services/constantService';
function attachEventListenersToGrid(gridOptions, gridType) {
  const deBouncer = debounce(e => createSaveUserSettingsHandler(gridOptions, gridType, e), 2000);
  const saveUserSettings = e => deBouncer(e);

  gridOptions.api.addEventListener('columnMoved', saveUserSettings);
  gridOptions.api.addEventListener('columnVisible', saveUserSettings);
  gridOptions.api.addEventListener('sortChanged', saveUserSettings);
  gridOptions.api.addEventListener('filterChanged', saveUserSettings);
  gridOptions.api.addEventListener('columnResized', saveUserSettings);
  gridOptions.api.addEventListener('columnPinned', saveUserSettings);
  gridOptions.api.addEventListener('columnValueChanged', saveUserSettings);
  gridOptions.api.addEventListener('columnRowGroupChanged', saveUserSettings);

  // gridOptions.api.addEventListener('displayedColumnsChanged', event => {
  //   console.log('*** displayedColumnsChanged:', { event, gridOptions });
  //   const latestColumnState = gridOptions?.api?.getColumnState();

  //   console.log('*** latestColumnState,', latestColumnState);
  //   const sortingLatestState = latestColumnState.map(col => col.colId);
  //   console.log('*** sortingLatestState,', sortingLatestState);
  //   const columnState = gridOptions?.api?.getColumns();
  //   console.log('*** columnState,', columnState);
  // });
  // gridOptions.api.addEventListener('columnValueChanged', event => {
  //   console.log('*** columnValueChanged event:', event);
  //   console.log('*** columnValueChanged gridOptions:', gridOptions);
  // });
  // gridOptions.api.addEventListener('columnGroupOpened', event => {
  //   console.log('*** columnGroupOpened event:', event);
  //   console.log('*** columnGroupOpened gridOptions:', gridOptions);
  // });

  // gridOptions.api.addEventListener('gridColumnsChanged', event => {
  //   console.log('*** gridColumnsChanged event:', event);
  //   console.log('*** gridColumnsChanged gridOptions:', gridOptions);
  // });
}

// Debounce utility function
// function debounce(func, delay) {
//   let timer;
//   return function (...args) {
//     clearTimeout(timer);
//     timer = setTimeout(() => {
//       func.apply(this, args);
//     }, delay);
//   };
// }

// Function that returns the `saveUserSettings` handler with gridOptions
function createSaveUserSettingsHandler(gridOptions, key, e) {
  // get the latest columns state
  const latestColumnState = gridOptions?.api?.getColumnState();

  if (!latestColumnState) return;
  const latestColumnStateAsObject = {};
  latestColumnState.forEach(columnData => {
    const data = omit(columnData, ['sort', 'sortIndex']);
    latestColumnStateAsObject[columnData.colId] = data;
  });

  const sortingLatestState = latestColumnState.map(col => col.colId);
  let columnState = gridOptions?.api?.getColumns().map(col => ({
    ...col?.colDef,
    visible: col?.visible,
    colId: col?.colId,
    renderComponent: col?.renderComponent ?? col?.cellRenderer,
    pinned: col?.pinned,
    ...latestColumnStateAsObject[col.colId],
    // Added this to avoid columns resetting sort automatically
    sort: null,
    sortIndex: null
  }));
  let updateColumnColumns = sortBy(columnState, col => sortingLatestState.indexOf(col.colId));

  const filterState = gridOptions?.api?.getFilterModel();
  const sortState = gridOptions.api
    ?.getColumnState()
    ?.filter(col => col.sort)
    .map(col => ({ colId: col.colId, sort: col.sort }));

  // stateUpdateFun && stateUpdateFun(updateColumnColumns);
  if (!updateColumnColumns.length) return; //do not save settings if columns are not present
  const userSettings = {
    settings: JSON.stringify(updateColumnColumns), //columns
    filter: JSON.stringify(filterState), // filters
    sort: JSON.stringify(sortState), //sorting
    grid_key: key //unique grid key
  };
  const toUrl = IGN_API.updateGridSetting;

  gridSettingsApi(POST, userSettings, '', toUrl)
    .then(res => {
      if (res?.status !== 200) console.error('grid settings not saved', res);
    })
    .catch(err => {
      console.error('error in saving grid setting', err);
    });
}

//created later for saving settings from centralized agGrid
export const saveGridApi = debounce(userSettings => {
  const toUrl = IGN_API.updateGridSetting;

  const settingsToSave = {
    settings: JSON.stringify(userSettings.settings || []),
    filter: JSON.stringify(userSettings.filter || {}),
    sort: JSON.stringify(userSettings.sort || []),
    grid_key: userSettings.grid_key || ''
  };

  gridSettingsApi(POST, settingsToSave, '', toUrl)
    .then(res => {
      if (res?.status !== 200) console.error('grid settings not saved', res);
    })
    .catch(err => {
      console.error('error in saving grid setting', err);
    });
}, 1200);

export default attachEventListenersToGrid;
