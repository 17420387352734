import { Box, Grid } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../../components/common/CustomButton';
import Loader from '../../../../components/common/Loader';
import CustomInputField from '../../../../components/common/StyledComponents/CustomInputField';
import ViewContactDrawer from '../../../../components/ViewContactDrawer';
import { getMethodWithCancelTokenApi } from '../../../../services/ApiService';
import { IGN_API } from '../../../../services/constantService';
import AgGridWrapper from '../../../../utils/AgGridWrapper';
import { SCROLL_TIMEOUT } from '../../../../utils/common';
import CustomModalWithHeader from '../../../Commons/CustomModalWithHeader';
import { CustomLoadingOverlayComponent } from '../../../Commons/Utils';
import { WorkBenchContext } from '../../Context';

export default function AddContactFromExisting() {
  const { setAddContactFromExistingModal, addContactFromExistingModal, addRecord, refetchFunc } = useContext(WorkBenchContext);
  const [rowData, setRowData] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [selectedIds, setSelectedIds] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const { t } = useTranslation();

  const onSelectionChanged = rows => {
    const selectedData = rows.map(node => {
      return {
        contact_id: node.id,
        id: undefined,
        ...node
      };
    });
    setSelectedIds(selectedData);
  };

  const fetchContacts = async () => {
    const url = `${IGN_API.picklists}/contacts?name=${inputValue}`;
    setLoading(true);
    if (inputValue && inputValue.length > 2) {
      await getMethodWithCancelTokenApi(url, {}, {}).then(response => {
        const { status, data } = response;
        if (status === 200) {
          const formattedData = data?.data?.map(item => {
            return {
              id: item?.id,
              name: `${item.first_name} ${item.last_name}`,
              contact_address: item.contact_address[0]?.address_lines,
              company: item?.contact_company?.name,
              current_job_title: item?.current_job_title,
              source: item?.source,
              updated_at: item?.updated_at ? new Date(item?.updated_at)?.toDateString() : '--'
            };
          });
          setRowData(formattedData);
          if (data?.data?.length === 0) {
            enqueueSnackbar('No data found', { variant: 'error' });
            setRowData([]);
          }
        }
      });
      setLoading(false);
    } else {
      enqueueSnackbar('Please enter atleast 3 characters to search', { variant: 'error' });
      setLoading(false);
    }
  };

  const addToContactWorkbench = async () => {
    try {
      setLoading(true);
      for (let data of selectedIds) {
        await addRecord(data?.contact_id, null);
      }
      enqueueSnackbar('Added to Workbench', 'success');
      setAddContactFromExistingModal(false);
      refetchFunc();
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setLoading(false);
    }
  };

  const openCandidateDrawer = id => {
    setAddContactFromExistingModal(false);
    setIsDrawerOpen(true);
    setSelectedContact(id);
  };
  const columnDefs = [
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
      filter: true,
      cellRenderer: ({ value }) => value
    },
    {
      headerName: 'Company',
      field: 'company',
      sortable: true,
      filter: true,
      cellRenderer: ({ value }) => value
    },
    {
      headerName: 'Job Title',
      field: 'current_job_title',
      sortable: true,
      filter: true,
      cellRendererFramework: ({ value }) => value
    },
    {
      headerName: 'Contact Address',
      field: 'contact_address',
      sortable: true,
      filter: true,
      cellRendererFramework: ({ value }) => value
    },
    {
      headerName: 'Updated At',
      field: 'updated_at',
      cellRendererFramework: ({ value }) => value,
      sortable: true,
      filter: true
    },
    {
      headerName: 'Source',
      field: 'source',
      sortable: true,
      filter: true,
      cellRendererFramework: ({ value }) => value
    }
  ];

  const onCloseDrawer = () => {
    setIsDrawerOpen(false);
    setAddContactFromExistingModal(true);
  };

  return (
    <>
      <CustomModalWithHeader
        isOpen={addContactFromExistingModal}
        style={{ width: '80vw !important', minHeight: '70vh' }}
        onClose={() => setAddContactFromExistingModal(!addContactFromExistingModal)}
        label={t('utils.addExistingContact')}
        closeIcon={true}
      >
        <div className='min-vw-50 p-4'>
          <Loader show={isLoading} />
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item xs={10}>
              <CustomInputField className='w-100' label={t('utils.contactName')} onChange={e => setInputValue(e.target.value)} />
            </Grid>
            <Grid item xs={2}>
              <CustomButton buttonText={t('common.search')} type='primary w-100' onClick={fetchContacts} />
            </Grid>
          </Grid>
          <div id='myGrid' className='mt-2 w-100 ag-theme-alpine ag-grid-container' style={{ height: '60vh' }}>
            <AgGridWrapper
              enableBrowserTooltips={true}
              rowSelection={{ mode: 'multiRow', selectAll: 'filtered' }}
              onRowSelected={params => {
                const rowCount = params?.api?.getSelectedRows();
                onSelectionChanged(rowCount);
              }}
              rowHeight={50}
              columnDefs={columnDefs}
              loadingOverlayComponent={CustomLoadingOverlayComponent}
              scrollbarWidth={12}
              showHeaderMenu={true}
              rowData={rowData}
              paginationPageSize={1000}
              defaultColDef={{
                resizable: true,
                filter: true,
                sortable: true
              }}
              blockLoadDebounceMillis={SCROLL_TIMEOUT}
              suppressHorizontalScroll={false}
              domLayout='normal'
              disableStaticMarkup
              rowGroupPanelShow='always'
              rowModelType='clientSide'
            />
          </div>

          <div className='mt-2 d-flex justify-content-end'>
            <CustomButton buttonText={t('utils.addToWorkbench')} disabled={selectedIds?.length == 0} onClick={addToContactWorkbench} />
          </div>
        </div>
      </CustomModalWithHeader>
      <Box id='view-contact-drawer' zIndex={100000000}>
        <>
          <ViewContactDrawer navigateToAllContacts={false} isDrawerOpen={isDrawerOpen} setIsDrawerOpen={onCloseDrawer} id={selectedContact} />
        </>
      </Box>
    </>
  );
}
