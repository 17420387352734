import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Switch } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import DatePickerCommon from '../common/DatePicker/DatePicker';
import ProbabilitySelection from '../common/FunctionalComponents/ProbabilitySelection';
import CustomInputField from '../common/StyledComponents/CustomInputField';
// import { formatDate } from '../../utils/date';
import BrandSelection from '../common/FunctionalComponents/BrandSelection';
import RevenueMarketSelection from '../common/FunctionalComponents/RevenueMarketSelection';
// import BasicPartnerSelection from '../common/FunctionalComponents/BasicPartnerSelection';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { FormControlLabel, InputLabel, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ignCompanyDataApi, ignContactDataApi, picklistDropDownApis, picklistsDataApis } from '../../services/ApiService';
import { DROP_DOWN_PICKLIST, ERROR, GET, PICKLISTS, POST, SUCCESS } from '../../services/constantService';
import useApi from '../../utils/Hooks/useApiHook';
import { getInputLabelPropsBasicDetails } from '../../utils/common';
import { formatDateUtcLocal } from '../../utils/date';
import CustomButton from '../common/CustomButton';
import CustomMuiDropdown from '../common/CustomMuiDropdown';
import BDProjectTypeSelection from '../common/FunctionalComponents/BDProjectTypeSelection';
import HorizontalPracticeSelection from '../common/FunctionalComponents/HorizontalPracticeSelection';
import JobFunctionSelection from '../common/FunctionalComponents/JobFunctionSelection';
import ResultSelection from '../common/FunctionalComponents/ResultSelection';
import VerticalPracticeSelection from '../common/FunctionalComponents/VerticalPracticeSelection';
import GeoLocationInput from '../common/GeoLocation';
import QuickAddContact from '../common/QuickAddContact/v1/QuickAddContact';
import { QUICK_ADD_CONTACT_COMMON_FIELD } from '../common/QuickAddContact/v1/utils/Constants';
import RichText from '../common/RichText';
import CustomNumbericField from '../common/StyledComponents/CustomNumericField';
// import ClientContacts from '../../Containers/Searches/ViewProject/ClientContacts';
import { i18nInterpolator } from '../../translations/index.js';
import CustomConfirmationPopup from '../common/CustomConfirmationPopup/index.js';
import DropdownWithCustomOptions from '../common/CustomMuiDropdown/DropdownWithCustomOptions.js';
import CompanySelectionDebounce from '../common/FunctionalComponents/CompanySelection/CompanySelectionDebounce.js';
import MultiSelectCompanySelectionDebounce from '../common/FunctionalComponents/CompanySelection/MultiSelectCompanyDebounce.js';

const BasicDetails = props => {
  const {
    register,
    setValue = () => {},
    currentValues,
    isEditing,
    watch,
    isProject = false,
    companyId,
    clientContacts,
    setClientContacts /* projectData */ /* , control, currentValues, isHide */
  } = props;
  const [jobFunction, setJobFunction] = useState([]);
  const [frameworkType, setFrameworkType] = useState(null);
  const [industries, setIndustries] = useState([]);
  const [contactByCompany, setContactByCompany] = useState([]);
  const [companyByBD, setCompanyByBD] = useState({});
  const [companyChangeId, setCompanyChangeId] = useState();
  const [minExperience, setMinExperience] = useState(null);
  const [minExperienceError, setMinExperienceError] = useState(false);
  const [maxExperience, setMaxExperience] = useState(null);
  const [maxExperienceError, setMaxExperienceError] = useState(false);
  const [minCompensation, setMinCompensation] = useState('');
  const [minCompensationError, setMinCompensationError] = useState(false);
  const [maxCompensation, setMaxCompensation] = useState('');
  const [maxCompensationError, setMaxCompensationError] = useState(false);
  const [openQuickAddContact, setOpenQuickAddContact] = useState(false);
  const { register: registerQAC, handleSubmit: handleSubmitQAC, control: controlQAC, setValue: setValueQAC, getValues: getValuesQAC } = useForm();
  const [isQACLoading, setIsQACLoading] = useState(false);
  const [openFrameworkChangePopup, setOpenFrameworkChangePopup] = useState({ open: false, short_desc: null, value: null });

  const tr = useTranslation();

  const { t } = useTranslation();

  useEffect(() => {
    let id = companyId ? companyId : companyChangeId ? companyChangeId : currentValues?.company_id;
    fetchContacts(id);
    setMinExperience(currentValues?.min_experience);
    setMaxExperience(currentValues?.max_experience);
    setMinCompensation(currentValues?.min_compensation || '');
    setMaxCompensation(currentValues?.max_compensation || '');
  }, [currentValues]);

  useEffect(() => {
    setCompanyByBD(currentValues?.ign_companies);
  }, [currentValues]);

  useEffect(() => {
    const type = currentValues?.framework_type || 'helia';
    setFrameworkType(type);
    setValue('framework_type', type, { shouldDirty: true });
  }, [currentValues?.framework_type]);

  const fetchContacts = async companyId => {
    if (companyId) {
      const contacts = await ignCompanyDataApi(GET, {}, companyId, '?tabName=ASSOCIATED_CONTACTS_COMPANIES_TAB');
      let contactData = Array.isArray(contacts?.data) && contacts?.data.length ? contacts?.data.filter(con => con?.is_present).map(comCon => comCon?.ign_contacts) : [];
      setContactByCompany(contactData || []);
    }
  };

  useEffect(() => {
    let id = companyId ? companyId : companyChangeId;
    fetchContacts(id);
  }, [currentValues, companyChangeId]);

  useEffect(() => {
    setValue('');
  }, []);

  useEffect(() => {
    register('is_confidential');
  }, [register]);

  const {
    data: jobTypeResponse
    // success: isJobTypeResponse
  } = useApi({
    queryFn: () => {
      return picklistsDataApis(GET, PICKLISTS.JOB_TYPE);
    }
  });
  const { data: currencyTypeResponse } = useApi({
    queryFn: () => {
      return picklistDropDownApis(GET, DROP_DOWN_PICKLIST.CURRENCIES);
    }
  });

  const {
    data: compensationFrequencyResponse
    // success: isCompensationFrequencyResponse,
  } = useApi({
    queryFn: () => {
      return picklistsDataApis(GET, PICKLISTS.COMPENSATION_FREQUENCY);
    }
  });

  const { data: targetIndustryOptionsResponse } = useApi({
    queryFn: () => {
      return picklistDropDownApis(GET, DROP_DOWN_PICKLIST.INDUSTRIES_PICKLIST);
    }
  });

  const { data: frameworkTypes } = useApi({
    queryFn: () => {
      return picklistsDataApis(GET, PICKLISTS.PROJECT_FRAMEWORK_TYPE);
    }
  });

  useEffect(() => {
    setValue('industries', currentValues?.industries, { shouldDirty: true });
    setIndustries(currentValues?.industries || []);
  }, [currentValues]);

  const handleJobFunctionChange = value => {
    setValue('job_functions', value, { shouldDirty: true });
    setJobFunction(value);
  };

  useEffect(() => {
    if (isEditing) {
      const field = [
        'ign_companies',
        'job_number',
        'stage_desc',
        'stage',
        'client_team',
        'job_title',
        'projected_start_date',
        'country',
        'basic_partners',
        'probability',
        'bd_status',
        'service_offering',
        'service_offering_desc',
        'markets',
        'competition',
        'market',
        'is_nda',
        'is_confidential',
        'project_client_team',
        'job_type',
        'result',
        'pay_frequency',
        'vertical_practice',
        'horizontal_practice',
        'currency',
        'bd_currency',
        'bd_currency_values',
        'project_type',
        'vc_pe',
        'loe_date',
        'min_experience',
        'max_experience',
        'start_date',
        'offhold_date',
        'end_date'
      ];
      field.forEach(fieldName => {
        if (fieldName === 'country') {
          setValue('country_id', currentValues[fieldName]?.id);
        } else if (fieldName === 'market') {
          setValue('market', currentValues['markets']);
        } else if (fieldName === 'stage') {
          setValue('stage', currentValues['stage'] ? currentValues['stage'] : recordType);
        } else {
          setValue(fieldName, currentValues[fieldName]);
        }
      });
      setIndustries(currentValues['industries'] || []);
    }
  }, [currentValues]);

  /*const getHiringManager = data => {
    if (data && data.length > 0) {
      // Check if data is not null and not empty
      const hiringManager = data.find(item => item && item.hiring_mgr); // Check if item exists and has hiring_mgr as true
      if (hiringManager) {
        return hiringManager.contact ? hiringManager.contact.name : ''; // Check if contact exists before accessing its name
      }
    }
    return ''; // Default value if no hiring manager is found
  };*/

  const handleQACClose = () => {
    setOpenQuickAddContact(false);
    clearAddContactInputs();
  };

  const schema = yup.object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().optional(),
    linkedin: yup.string().url().optional(),
    phonenumber: yup
      .string()
      .transform((value, originalValue) => {
        // Coerce empty string or null to null
        if (originalValue === '' || originalValue === null) return null;
        return value;
      })
      .matches(/^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/, 'Phone number is not valid')
      .nullable(),
    jobtitle: yup.string().optional()
  });

  const clearAddContactInputs = () => {
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.first_name, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.firstName, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.last_name, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.lastName, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.phonenumber, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.contact_phones, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.email, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.contact_emails, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.linkedin, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.linkedin_url, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.jobtitle, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.current_job_title, null);
  };

  const onAddSubmit = (contact, allowDuplicate = false) => {
    schema
      .validate(contact)
      .then(async () => {
        try {
          setIsQACLoading(true);
          if (!contact?.email && !contact?.linkedin && !contact?.phonenumber) {
            enqueueSnackbar('Email/LinkedIn/Phone number is required.', { variant: ERROR });
            return;
          } else {
            const sub_route = allowDuplicate ? '?allowDuplicate=true' : '';
            const updatedEmail = contact.contact_emails?.map(emailObj => ({
              ...emailObj,
              email_type: emailObj.email_type?.field_value || 'Personal'
            }));
            const updatedPhone = contact.contact_phones?.map(phoneObj => ({
              ...phoneObj,
              phone_number: phoneObj.phone,
              phone_type: phoneObj.phone_type?.field_value || 'Personal'
            }));
            const updatedContact = { ...contact, contact_emails: updatedEmail, contact_phones: updatedPhone };
            const final = updatedContact;
            const { status, data } = await ignContactDataApi(POST, '', final, sub_route);
            if (status === 500) {
              enqueueSnackbar(data.message, { variant: ERROR });
            } else {
              let fullName = `${final[QUICK_ADD_CONTACT_COMMON_FIELD.first_name]} ${final[QUICK_ADD_CONTACT_COMMON_FIELD.last_name]}`;
              let contact_attendees = contactByCompany || [];
              setContactByCompany([...contact_attendees, { id: data.id, name: fullName, ...final }]);
              enqueueSnackbar('Contact added successfully', { variant: SUCCESS });

              clearAddContactInputs();
              setOpenQuickAddContact(false);
            }
          }
        } catch (err) {
          enqueueSnackbar(err, { variant: ERROR });
        } finally {
          // clearInputs();
          setIsQACLoading(false);
        }
      })
      .catch(err => {
        enqueueSnackbar(err, { variant: ERROR });
        setIsQACLoading(false);
      });
  };

  const handleCompanySelectionChange = (e, data) => {
    const companyId = data?.[0]?.id || data?.id || '';
    setCompanyChangeId(companyId);
  };
  const handleIndustryChange = (e, value) => {
    setValue('industries', value, { shouldDirty: true });
    setIndustries(value);
  };
  const handleClientContactChange = (e, value) => {
    if (value) {
      setValue('client_id', value?.id, { shouldDirty: true });
      setValue(
        'project_client_team',
        [
          {
            ...value,
            contact_id: value?.id,
            hiring_mgr: false
          }
        ],
        { shouldDirty: true }
      );
      setClientContacts({
        client_id: value?.id,
        name: value?.name
      });
    }
  };

  const handleFrameworkTypeChange = (e, value) => {
    if (value?.field_value === 'ignyte_intelligence_assisted' && isEditing) {
      setValue('framework_type', value?.field_value, { shouldDirty: true });
      setOpenFrameworkChangePopup({ open: true, short_desc: value?.short_desc, value: value?.field_value });
    } else {
      setValue('framework_type', value?.field_value, { shouldDirty: true });
      setFrameworkType(value?.field_value);
    }
  };

  /*const handleChangeHiringManager = data => {
    if (data) {
      const hiringManager = { ...data, hiring_mgr: true }; // Add hiring_mgr separately
      setValue('hiringManager', hiringManager);

      const projectClientTeam = watch('project_client_team');
      if (projectClientTeam && projectClientTeam.length > 0) {
        const existingItem = projectClientTeam.find(existing => existing.name === hiringManager.id);
        if (!existingItem) {
          // Update the state of project_client_team using setWatch (assuming it's a state variable)
          setValue('project_client_team', [...projectClientTeam, hiringManager]);
        }
      } else {
        // If project_client_team is empty, initialize it with hiringManager
        setValue('project_client_team', [hiringManager]);
      }
    } else {
      setValue('hiringManager', data);
    }
  };*/

  let recordType = currentValues?.record_type === 'Business Development' ? 'BD Draft' : 'Draft';
  const frameworkOptions = frameworkTypes?.data || [];
  const handleConfirmPopup = () => {
    setFrameworkType(openFrameworkChangePopup?.value);
    setOpenFrameworkChangePopup({ open: false, value: null });
  };

  const handleCancelPopup = () => {
    setOpenFrameworkChangePopup({ open: false, value: null });
  };

  const industryOptions = useMemo(() => {
    return targetIndustryOptionsResponse?.data?.map(i => i?.name);
  }, [targetIndustryOptionsResponse]);

  return (
    <Box className='mt-2' sx={{ position: 'relative' }}>
      {openFrameworkChangePopup?.open && isEditing && (
        <CustomConfirmationPopup
          customMessage={i18nInterpolator(t('project.framework_type_popup_msg'), { framework_type: openFrameworkChangePopup?.short_desc })}
          open={openFrameworkChangePopup?.open}
          onConfirm={handleConfirmPopup}
          onCancel={handleCancelPopup}
          onClose={handleCancelPopup}
          cancelText={t('actions.cancel')}
          confirmText={t('actions.ok')}
          setOpen={handleCancelPopup}
        />
      )}

      <div className='fs-18 title-basis'>
        <b>Basic</b>
      </div>
      <Box id='education-experiences'>
        <Box className='my-cards flex flex-wrap'>
          <Box className='form-section flex flex-column'>
            {/* {!isProject && <Box className="text-label text-center fs-16 pl-1 pb-1 header-font my-4">
              {t('contacts.addContact.basicInfo')}
            </Box>} */}
            <Box className='flex flex-row py-2' sx={{ marginLeft: '-15px', marginRight: '-15px' }}>
              <Box className='col-md-4'>
                <CompanySelectionDebounce
                  viewAddress={true}
                  defaultValue={currentValues?.ign_companies?.name}
                  value={watch('ign_companies')}
                  label={t('contacts.addContact.company')}
                  onChange={(e, data) => {
                    setValue('ign_companies', data, { shouldDirty: true });
                    handleCompanySelectionChange(e, data);
                  }}
                  required={true}
                  disabled={false}
                  size='small'
                  InputLabelProps={{
                    style: getInputLabelPropsBasicDetails()
                  }}
                  className={'w-100'}
                  isForAddCompany={true}
                  addAnotherButton={false}
                  ignoreFiltering
                  style={{ width: '100%' }}
                />
              </Box>
              <Box className='col-md-4'>
                <CustomInputField
                  defaultValue={currentValues?.job_title || ''}
                  value={watch('job_title')}
                  label={t('contacts.addContact.jobTitle')}
                  required={true}
                  onChange={e => {
                    setValue('job_title', e.target.value, { shouldDirty: true });
                  }}
                  size='small'
                  InputLabelProps={{
                    style: getInputLabelPropsBasicDetails(),
                    shrink: watch('job_title') || currentValues?.job_title ? true : false
                  }}
                />
              </Box>
              <Box className='col-md-4 multiInput'>
                <CustomMuiDropdown
                  className={'w-100'}
                  options={frameworkOptions}
                  getOptionLabel={option => option?.short_desc || ''}
                  value={frameworkOptions?.find(option => option?.field_value === frameworkType) || null}
                  isOptionEqualToValue={(option, value) => option?.field_value === value?.field_value}
                  onChange={handleFrameworkTypeChange}
                  label={tr.t('fields.framework_type')}
                  required={true}
                  size='small'
                  InputLabelProps={{
                    style: getInputLabelPropsBasicDetails()
                  }}
                />
              </Box>
              {/* <Box className='col-md-4'>
                <SearchStageSelection
                  value={watch('stage') || (currentValues?.stage ? currentValues?.stage : recordType || null)}
                  defaultValue={currentValues?.stage ? currentValues?.stage : recordType || null}
                  onChange={() => {
                    setValue('stage', currentValues?.stage);
                    setValue('stage_desc', currentValues?.stage_desc || null, { shouldDirty: true });
                  }}
                  label={t('utils.stage')}
                  required={true}
                  disabled={true}
                  className={'w-100'}
                  size='small'
                />
              </Box> */}
            </Box>

            <Box className='flex flex-row py-2' sx={{ marginLeft: '-15px', marginRight: '-15px' }}>
              <Box className='col-md-4'>
                <DatePickerCommon
                  value={watch('projected_start_date') ? formatDateUtcLocal(watch('projected_start_date'), 'MM/DD/YYYY') : null}
                  format='MM/DD/YYYY'
                  placeholder='mm/dd/yyyy'
                  onChange={e => {
                    const newDate = e?.$d ? formatDateUtcLocal(e?.$d, 'MM-DD-YYYY') : null;
                    setValue('projected_start_date', newDate, { shouldDirty: true });
                  }}
                  label={t('utils.projectedStartDate')}
                  required={true}
                  className={'w-100 DatePickerRequired'}
                  isDefaultDatePickerStyle={false}
                  slotProps={{
                    textField: {
                      size: 'small',
                      InputLabelProps: {
                        style: getInputLabelPropsBasicDetails()
                      }
                    }
                  }}
                />
              </Box>
              <Box className='col-md-4'>
                <GeoLocationInput
                  val={currentValues?.location ? currentValues?.location : currentValues?.country ? currentValues?.country?.name : '' || ''}
                  onChange={(event, location) => {
                    setValue('location', location?.description, { shouldDirty: true });
                    setValue('location_place_id', location?.place_id);
                  }}
                  label={tr.t('fields.location')}
                  required={true}
                  className='w-100'
                  size='small'
                  InputLabelProps={{
                    style: getInputLabelPropsBasicDetails()
                  }}
                />
              </Box>
              <Box className='col-md-4'>
                <BrandSelection
                  value={watch('service_offering_desc') ? watch('service_offering_desc') : watch('service_offering') || null}
                  onChange={(e, data) => {
                    setValue('service_offering', data?.field_value, { shouldDirty: true });
                    setValue('service_offering_desc', data, { shouldDirty: true });
                  }}
                  label={t('utils.serviceOffering')}
                  required={true}
                  className={'w-100'}
                  size='small'
                  InputLabelProps={{
                    style: getInputLabelPropsBasicDetails()
                  }}
                />
              </Box>
            </Box>

            <Box className='flex flex-row py-2' sx={{ marginLeft: '-15px', marginRight: '-15px' }}>
              {/* {currentValues.record_type === 'Project' && (
                <Box className='col-md-4'>
                  <BDStatusSelection
                    defaultValue={currentValues?.bd_status || null}
                    value={watch('bd_status') || null}
                    onChange={(e, data) => {
                      setValue('bd_status', data?.short_desc, { shouldDirty: true });
                    }}
                    label={t('utils.bdStatus')}
                    className={'w-100'}
                    size='small'
                    InputLabelProps={{
                      style: getInputLabelPropsBasicDetails()
                    }}
                  />
                </Box>
              )} */}
              <Box className='col-md-4'>
                <ProbabilitySelection
                  defaultValue={currentValues?.probability || null}
                  value={watch('probability') || null}
                  onChange={(e, data) => {
                    setValue('probability', data?.field_value, { shouldDirty: true });
                  }}
                  label={`${t('utils.probability')} %`}
                  required={true}
                  className={'w-100'}
                  size='small'
                  InputLabelProps={{
                    style: getInputLabelPropsBasicDetails()
                  }}
                />
              </Box>
              <Box className={'col-md-4'}>
                <RevenueMarketSelection
                  defaultValue={currentValues?.markets?.market || null}
                  value={watch('markets')?.market || null}
                  onChange={(e, data) => {
                    setValue('markets', data, { shouldDirty: true });
                  }}
                  label={tr.t('fields.geography')}
                  required={true}
                  className={'w-100'}
                  size='small'
                  InputLabelProps={{
                    style: getInputLabelPropsBasicDetails()
                  }}
                />
              </Box>
              <Box className='col-md-4 multiInput'>
                <DropdownWithCustomOptions
                  className={'w-100'}
                  multiple
                  options={industryOptions ?? []}
                  getOptionLabel={option => option}
                  isOptionEqualToValue={(option, value) => option === value}
                  value={industries}
                  onChange={handleIndustryChange}
                  label={t('contacts.addContact.industry')}
                  size='small'
                  InputLabelProps={{
                    style: getInputLabelPropsBasicDetails()
                  }}
                  useLocalInputState
                  maxVisibleTags={1}
                  required
                />
              </Box>
            </Box>

            <Box className='flex flex-row py-2' sx={{ marginLeft: '-15px', marginRight: '-15px' }}>
              <Box className='col-md-4 multiInput'>
                <QuickAddContact
                  useFormController={{ register: registerQAC, handleSubmit: handleSubmitQAC, control: controlQAC, setValue: setValueQAC, getValues: getValuesQAC }}
                  open={openQuickAddContact}
                  setOpen={setOpenQuickAddContact}
                  handleClose={handleQACClose}
                  onAddSubmit={onAddSubmit}
                  isAddDisabled={false}
                  clearQuickAddContactData={clearAddContactInputs}
                  isQACLoading={isQACLoading}
                />
                <CustomMuiDropdown
                  className={'w-100'}
                  options={contactByCompany}
                  getOptionLabel={option => option.name || ''}
                  value={Object.keys(clientContacts).length ? clientContacts : currentValues?.project_client_team?.[0]?.contact}
                  onChange={handleClientContactChange}
                  defaultValue={currentValues?.project_client_team?.[0]?.contact}
                  label={t('activityTypeSetup.client-contacts')}
                  required={false}
                  size='small'
                  InputLabelProps={{
                    style: getInputLabelPropsBasicDetails()
                  }}
                  noOptionComponent={
                    <CustomButton
                      variant='text'
                      onClick={() => {
                        setOpenQuickAddContact(true);
                      }}
                      type={'tertiary'}
                      size={'small'}
                      buttonText={'Add Contact'}
                      iconLeft={
                        <AddCircleIcon
                          sx={{
                            width: '2vw',
                            height: '2vh',
                            paddingRight: 1
                          }}
                        />
                      }
                    />
                  }
                />
              </Box>
            </Box>

            <Box className='flex flex-row py-2' sx={{ marginLeft: '-15px', marginRight: '-15px' }}>
              <Box className='col-md-12 multiInput'>
                <JobFunctionSelection
                  multiple={true}
                  defaultValue={currentValues?.job_functions}
                  value={currentValues?.job_functions}
                  onChange={value => {
                    handleJobFunctionChange(value);
                  }}
                  label={t('utils.jobFunction')}
                  isCheckBox={true}
                  disableCloseOnSelect={true}
                  selectedList={jobFunction}
                  className={'w-100'}
                  size='small'
                  InputLabelProps={{
                    style: getInputLabelPropsBasicDetails()
                  }}
                />
              </Box>
            </Box>

            <Accordion defaultExpanded={true} className='panel-bottom-margin w-100 p-0' style={{ background: '#fff', color: '#fff' }}>
              <AccordionSummary className='accordion-header' expandIcon={<ExpandMoreIcon className='accordion-expand-icon' />} aria-controls='panel1a-content'>
                <div>Additional Information</div>
              </AccordionSummary>
              <AccordionDetails className='px-0 accordion-body'>
                <Box className='flex flex-row justify-content-between py-2'>
                  <Box className='col-md-4'>
                    <CustomMuiDropdown
                      options={jobTypeResponse?.data ?? []}
                      multiple={false}
                      defaultValue={currentValues?.job_type || null}
                      value={watch('job_type') || null}
                      onChange={(event, data) => {
                        setValue('job_type', data?.field_value, { shouldDirty: true });
                      }}
                      label={t('utils.jobType')}
                      name='jobType'
                      className={'w-100'}
                      getOptionLabel={option => {
                        if (typeof option === 'string') {
                          return jobTypeResponse?.data?.find(item => item?.field_value === option)?.short_desc;
                        }
                        return option.short_desc;
                      }}
                      size='small'
                      InputLabelProps={{
                        style: getInputLabelPropsBasicDetails()
                      }}
                    />
                  </Box>
                  <Box className='col-md-4 flex flex-row justify-content-between'>
                    <Box width={'47%'}>
                      <CustomInputField
                        className={'w-100'}
                        size='small'
                        label={t('project.candidateScore.experienceLabelFromYears')}
                        type='number'
                        name='experienceFrom'
                        fullWidth={false}
                        defaultValue={currentValues?.min_experience || ''}
                        value={watch('min_experience')}
                        inputPropsMin={0}
                        error={minExperienceError}
                        onChange={e => {
                          const newValue = e.target.value;
                          if (newValue === '' || (Number(newValue) >= 0 && !isNaN(newValue))) {
                            setMinExperienceError(false);
                            setValue('min_experience', newValue, { shouldDirty: true });
                            setMinExperience(newValue);
                          } else {
                            setValue('min_experience', 0, { shouldDirty: true });
                            setMinExperience(0);
                            setMinExperienceError(true);
                          }
                        }}
                        InputLabelProps={{
                          style: getInputLabelPropsBasicDetails(),
                          shrink: watch('min_experience') || currentValues?.min_experience ? true : false
                        }}
                      />
                    </Box>
                    <Box width={'47%'}>
                      <CustomInputField
                        className={'w-100'}
                        size='small'
                        label={t('project.candidateScore.experienceLabelToYears')}
                        variant='outlined'
                        color='secondary'
                        type='number'
                        fullWidth={false}
                        name='experienceTo'
                        defaultValue={currentValues?.max_experience || ''}
                        value={watch('max_experience')}
                        inputPropsMin={0}
                        error={maxExperienceError}
                        onChange={e => {
                          const newValue = e.target.value;
                          if (newValue === '' || (Number(newValue) >= 0 && !isNaN(newValue))) {
                            setMaxExperienceError(false);
                            setValue('max_experience', newValue, { shouldDirty: true });
                            setMaxExperience(newValue);
                          } else {
                            setValue('max_experience', 0, { shouldDirty: true });
                            setMaxExperience(0);
                            setMaxExperienceError(true);
                          }
                        }}
                        InputLabelProps={{
                          style: getInputLabelPropsBasicDetails(),
                          shrink: watch('max_experience') || currentValues?.max_experience ? true : false
                        }}
                      />
                    </Box>
                  </Box>
                  <Box className='col-md-4'>
                    <ResultSelection
                      defaultValue={currentValues?.result || null}
                      value={watch('result') || null}
                      onChange={(e, data) => {
                        setValue('result', data?.field_value, { shouldDirty: true });
                      }}
                      label={`${t('utils.result')}`}
                      className={'w-100'}
                      size='small'
                      InputLabelProps={{
                        style: getInputLabelPropsBasicDetails()
                      }}
                    />
                  </Box>
                </Box>
                <Box className='flex flex-row justify-content-between py-2'>
                  <Box className='col-md-4'>
                    <CustomMuiDropdown
                      getOptionLabel={option => {
                        if (typeof option === 'string') {
                          return compensationFrequencyResponse?.data?.find(item => item?.field_value === option)?.short_desc;
                        }
                        return option.short_desc;
                      }}
                      options={compensationFrequencyResponse?.data ?? []}
                      multiple={false}
                      defaultValue={currentValues?.pay_frequency || null}
                      value={watch('pay_frequency') || null}
                      onChange={(event, data) => {
                        setValue('pay_frequency', data?.field_value, { shouldDirty: true });
                      }}
                      label={t('reports.candidate_details_information_compensationFrequency')}
                      name='compensationFrequency'
                      className={'w-100'}
                      size='small'
                      InputLabelProps={{
                        style: getInputLabelPropsBasicDetails()
                      }}
                    />
                  </Box>
                  <Box className='col-md-4'>
                    <VerticalPracticeSelection
                      defaultValue={currentValues?.vertical_practice || null}
                      value={watch('vertical_practice') || null}
                      onChange={(e, data) => {
                        setValue('vertical_practice', data?.field_value, { shouldDirty: true });
                      }}
                      label={`${t('utils.verticalPractice')}`}
                      className={'w-100'}
                      size='small'
                      InputLabelProps={{
                        style: getInputLabelPropsBasicDetails()
                      }}
                    />
                  </Box>
                  <Box className='col-md-4'>
                    <HorizontalPracticeSelection
                      defaultValue={currentValues?.horizontal_practice || null}
                      value={watch('horizontal_practice') || null}
                      onChange={(e, data) => {
                        setValue('horizontal_practice', data?.field_value, { shouldDirty: true });
                      }}
                      label={`${t('utils.horizontalPractice')}`}
                      className={'w-100'}
                      size='small'
                      InputLabelProps={{
                        style: getInputLabelPropsBasicDetails()
                      }}
                    />
                  </Box>
                </Box>
                <Box className='flex flex-row justify-content-between py-2'>
                  <Box className='col-md-4'>
                    <CustomMuiDropdown
                      options={currencyTypeResponse?.data ?? []}
                      getOptionLabel={option => {
                        if (typeof option === 'string') {
                          return currencyTypeResponse?.data?.find(item => item?.field_value === option)?.code;
                        }
                        return option.code;
                      }}
                      multiple={false}
                      defaultValue={currentValues?.bd_currency_values ? currentValues?.bd_currency_values : null}
                      value={watch('bd_currency_values') ? watch('bd_currency_values') : null}
                      onChange={(event, data) => {
                        // console.log('=====', data);
                        setValue('currency', data?.field_value, { shouldDirty: true });
                        setValue('bd_currency', data?.id, { shouldDirty: true });
                        setValue('bd_currency_values', data, { shouldDirty: true });
                      }}
                      label={t('utils.currency')}
                      name='currencyType'
                      className={'w-100'}
                      size='small'
                      InputLabelProps={{
                        style: getInputLabelPropsBasicDetails()
                      }}
                    />
                  </Box>
                  <Box className='col-md-4'>
                    <CustomNumbericField
                      value={watch('min_compensation') || minCompensation}
                      label={t('utils.minCompensation')}
                      onChange={value => {
                        const newValue = parseInt(value, 10);
                        if (newValue === '' || (newValue >= 0 && !isNaN(newValue))) {
                          setMinCompensationError(false);
                          setValue('min_compensation', value, { shouldDirty: true });
                          setMinCompensation(newValue);
                        } else {
                          setValue('min_compensation', 0, { shouldDirty: true });
                          setMinCompensation(0);
                          setMinCompensationError(true);
                        }
                      }}
                      InputLabelProps={{
                        style: getInputLabelPropsBasicDetails()
                      }}
                      prefix={(watch('bd_currency_values') ? watch('bd_currency_values')?.currency_icon : '') + ' '}
                    />
                  </Box>
                  <Box className='col-md-4'>
                    <CustomNumbericField
                      value={watch('max_compensation') || maxCompensation}
                      label={t('utils.maxCompensation')}
                      onChange={value => {
                        const newValue = parseInt(value, 10);
                        if (newValue === '' || (newValue >= 0 && !isNaN(newValue))) {
                          setMaxCompensationError(false);
                          setValue('max_compensation', value, { shouldDirty: true });
                          setMaxCompensation(newValue);
                        } else {
                          setValue('max_compensation', 0, { shouldDirty: true });
                          setMaxCompensation(0);
                          setMaxCompensationError(true);
                        }
                      }}
                      InputLabelProps={{
                        style: getInputLabelPropsBasicDetails()
                      }}
                      prefix={(watch('bd_currency_values') ? watch('bd_currency_values')?.currency_icon : '') + ' '}
                    />
                  </Box>
                </Box>
                <Box className='flex flex-row py-2'>
                  <Box className='col-md-4'>
                    <CustomInputField
                      value={watch('competition') || currentValues?.competition || null}
                      label={t('utils.competition')}
                      onChange={e => {
                        setValue('competition', e.target.value, { shouldDirty: true });
                      }}
                      size='small'
                      InputLabelProps={{
                        style: getInputLabelPropsBasicDetails()
                      }}
                    />
                  </Box>
                  <Box className='col-md-4'>
                    <BDProjectTypeSelection
                      defaultValue={currentValues?.project_type || null}
                      value={watch('project_type') || null}
                      onChange={(e, data) => {
                        setValue('project_type', data?.field_value, { shouldDirty: true });
                      }}
                      label={tr.t('project.projectType')}
                      className={'w-100'}
                      size='small'
                      InputLabelProps={{
                        style: getInputLabelPropsBasicDetails()
                      }}
                    />
                  </Box>
                </Box>

                <Box className='flex flex-row justify-content-between py-2'>
                  <Box className='col-md-12 multiInput'>
                    <MultiSelectCompanySelectionDebounce
                      label={`${tr.t('module.project')} VC/PE`}
                      defaultValue={currentValues?.vc_pe || []}
                      value={watch('vc_pe') || []}
                      onChange={(e, data) => {
                        const listVCPE = data.map(item => {
                          if (typeof item === 'string') {
                            return item;
                          }
                          return item.name;
                        });
                        setValue('vc_pe', listVCPE ? listVCPE : [], { shouldDirty: true });
                      }}
                      className={'w-100'}
                      size='small'
                      disableCloseOnSelect={true}
                      multiple={true}
                      isCheckBox={true}
                    />
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded={true} className='panel-bottom-margin w-100 p-0' style={{ background: '#fff', color: '#fff' }}>
              <AccordionSummary className='accordion-header' expandIcon={<ExpandMoreIcon className='accordion-expand-icon' />} aria-controls='panel1a-content'>
                <div>Timeline</div>
              </AccordionSummary>
              <AccordionDetails className='px-0 accordion-body'>
                <Box className='flex flex-row justify-content-between py-2'>
                  <Box className='col-md-4'>
                    <DatePickerCommon
                      value={currentValues?.start_date ? formatDateUtcLocal(currentValues?.start_date, 'MM/DD/YYYY') : null}
                      format='MM/DD/YYYY'
                      onChange={e => {
                        const newDate = e?.$d ? formatDateUtcLocal(e?.$d, 'MM-DD-YYYY') : null;
                        setValue('start_date', newDate, { shouldDirty: true });
                      }}
                      label={tr.t('project.timeline.startDate')}
                      required={true}
                      className={'w-100'}
                      isDefaultDatePickerStyle={false}
                      slotProps={{
                        textField: {
                          size: 'small',
                          sx: {
                            flexShrink: true
                          },
                          InputLabelProps: {
                            style: getInputLabelPropsBasicDetails()
                          }
                        }
                      }}
                    />
                  </Box>
                  <Box className='col-md-4'>
                    <DatePickerCommon
                      value={watch('loe_date') || currentValues?.loe_date ? formatDateUtcLocal(currentValues?.loe_date, 'MM/DD/YYYY') : null}
                      format='MM/DD/YYYY'
                      onChange={e => {
                        const newDate = e?.$d ? formatDateUtcLocal(e?.$d, 'MM-DD-YYYY') : null;
                        setValue('loe_date', newDate, { shouldDirty: true });
                      }}
                      label={tr.t('project.timeline.loeDate')}
                      required={true}
                      className={'w-100'}
                      isDefaultDatePickerStyle={false}
                      slotProps={{
                        textField: {
                          size: 'small',
                          InputLabelProps: {
                            style: getInputLabelPropsBasicDetails()
                          }
                        }
                      }}
                    />
                  </Box>
                  <Box className='col-md-4'>
                    <DatePickerCommon
                      value={currentValues?.onhold_date ? formatDateUtcLocal(currentValues?.onhold_date, 'MM/DD/YYYY') : null}
                      format='MM/DD/YYYY'
                      onChange={e => {
                        const newDate = e?.$d ? formatDateUtcLocal(e?.$d, 'MM-DD-YYYY') : null;
                        setValue('onhold_date', newDate, { shouldDirty: true });
                      }}
                      label={tr.t('project.timeline.onholdDate')}
                      required={true}
                      className={'w-100'}
                      isDefaultDatePickerStyle={false}
                      slotProps={{
                        textField: {
                          size: 'small',
                          InputLabelProps: {
                            style: getInputLabelPropsBasicDetails()
                          }
                        }
                      }}
                    />
                  </Box>
                </Box>

                <Box className='flex flex-row py-2'>
                  <Box className='col-md-4'>
                    <DatePickerCommon
                      value={currentValues?.offhold_date ? formatDateUtcLocal(currentValues?.offhold_date, 'MM/DD/YYYY') : null}
                      format='MM/DD/YYYY'
                      onChange={e => {
                        const newDate = e?.$d ? formatDateUtcLocal(e?.$d, 'MM-DD-YYYY') : null;
                        setValue('offhold_date', newDate, { shouldDirty: true });
                      }}
                      label={tr.t('project.timeline.offholdDate')}
                      required={true}
                      className={'w-100'}
                      isDefaultDatePickerStyle={false}
                      slotProps={{
                        textField: {
                          size: 'small',
                          InputLabelProps: {
                            style: getInputLabelPropsBasicDetails()
                          }
                        }
                      }}
                    />
                  </Box>
                  <Box className='col-md-4'>
                    <DatePickerCommon
                      value={currentValues?.end_date ? formatDateUtcLocal(currentValues?.end_date, 'MM/DD/YYYY') : null}
                      format='MM/DD/YYYY'
                      onChange={e => {
                        const newDate = e?.$d ? formatDateUtcLocal(e?.$d, 'MM-DD-YYYY') : null;
                        setValue('end_date', newDate, { shouldDirty: true });
                      }}
                      label={tr.t('project.timeline.endDate')}
                      required={true}
                      className={'w-100'}
                      isDefaultDatePickerStyle={false}
                      slotProps={{
                        textField: {
                          size: 'small',
                          InputLabelProps: {
                            style: getInputLabelPropsBasicDetails()
                          }
                        }
                      }}
                    />
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>

            <Box className='flex flex-row flex-wrap d-flex'>
              <Box className='row d-flex ml-0'>
                <Box className='col-md-6'>
                  <Box className='education-input-base'>
                    <Box className='education-input d-flex' sx={{ width: '25vw' }}>
                      <Typography className='p-2 text-dark' variant='body2'>
                        {'Confidential'}
                      </Typography>
                      <FormControlLabel
                        className='mb-0'
                        control={
                          <Switch
                            {...register('is_confidential')}
                            className='SwitchStyle'
                            checked={watch('is_confidential') || false}
                            onChange={e => {
                              setValue('is_confidential', e.target.checked);
                            }}
                          ></Switch>
                        }
                      />
                    </Box>
                  </Box>
                </Box>
                <Box className='col-md-6'>
                  <Box className='education-input-base'>
                    <Box className='education-input multiInput d-flex' sx={{ width: '25vw' }}>
                      <Typography className='p-2  text-dark' variant='body2'>
                        {'Non-Disclosure Agreement'}
                      </Typography>
                      <FormControlLabel
                        className='mb-0'
                        control={
                          <Switch
                            className='SwitchStyle'
                            checked={watch('is_nda') || false}
                            onChange={e => {
                              setValue('is_nda', e.target.checked);
                            }}
                          ></Switch>
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className='row d-flex mr-0 ml-0'>
              <Box className='col-md-12'>
                <Box className='education-input-base'>
                  <Box className='education-input' sx={{ width: '100%' }}>
                    <InputLabel>{t('utils.additionalInformation')}</InputLabel>
                    <RichText
                      defaultValue={currentValues?.additional_info || ''}
                      value={watch('additional_info') || ''}
                      onChange={data => {
                        setValue('additional_info', data, { shouldDirty: true });
                      }}
                      style={{ minHeight: '150px' }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* here */}

            <Box className='flex flex-row flex-wrap d-flex p-2 pl-3 '>
              <Box className='row d-flex'>
                <Box className='col-md-4'>
                  <Box className='education-input-base'>
                    <Box className='education-input' sx={{ width: '18vw' }}>
                      {/* <RevenueMarketSelection
                        defaultValue={currentValues?.markets?.market || null}
                        onChange={(e, data) => {
                          setValue('markets', data, { shouldDirty: true });
                        }}
                        label={t('fields.geography')}
                        required={true}
                      /> */}
                    </Box>
                  </Box>
                </Box>
                <Box className='col-md-4'>
                  <Box className='education-input-base'>
                    <Box className='education-input' sx={{ width: '18vw' }}>
                      {/* {isProject && (
                        <SearchStageSelection
                          defaultValue={currentValues.billing_status_desc?.short_desc === 'None' ? '--' : currentValues.billing_status_desc?.short_desc || '--'}
                          // onChange={() => {
                          //   setValue('billing_desc', currentValues?.stage)
                          //   setValue('stage_desc', currentValues?.stage_desc || null, { shouldDirty: true })
                          // }
                          // }
                          label='Billing Status'
                          required={true}
                          disabled={true}
                        />
                      )} */}
                    </Box>
                  </Box>
                </Box>

                <Box className='col-md-4'>
                  <Box className='education-input-base'>
                    <Box className='education-input multiInput' sx={{ width: '18vw' }}></Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

BasicDetails.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  control: PropTypes.object,
  watch: PropTypes.func,
  userRef: PropTypes.object,
  currentValues: PropTypes.object,
  isHide: PropTypes.bool,
  isEditing: PropTypes.bool,
  isProject: PropTypes.bool,
  companyId: PropTypes.string,
  projectData: PropTypes.object,
  clientContacts: PropTypes.array,
  setClientContacts: PropTypes.func
};
export default BasicDetails;
