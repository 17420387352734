import { Box, Checkbox, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { GET, TAG_SETUP_LOGS_MESSAGE } from '../../../../services/constantService';
import TotalCount from '../../../common/TotalCount';
// import { ignCompanyDataApi } from '../../../../services/ApiService';
import { Link, useParams } from 'react-router-dom';
import { SUCCESS_STATUS_CODE } from '../../../../Containers/Contacts/utils';
import { ignCompanyDataApi } from '../../../../services/ApiService';
import AgGridWrapper from '../../../../utils/AgGridWrapper';
import { columnInvoicesDefs } from '../../utils';
let gridApi, columnApi;
const ViewInvoices = ({ isHeaderNav }) => {
  const { id } = useParams();
  const [rowCount, setRowCount] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [, /*isAllRowsSelected*/ setIsAllRowsSelected] = useState(false);
  const [isGridReady, setIsGridReady] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sub_route = '?tabName=INVOICES_TAB';
        const { status, data } = await ignCompanyDataApi(GET, '', id, sub_route);
        if (status === SUCCESS_STATUS_CODE) {
          setRowData(data || []);
          setRowCount(data?.length || 0);
          // if (data?.length === 0) {
          //   const message = notFoundMessage("records");
          //   enqueueSnackbar(message, { variant: WARNING });
          // }
        } else {
          throw new Error(TAG_SETUP_LOGS_MESSAGE.GET_ROWS_FOUND_ERROR);
        }
      } catch (error) {
        console.error(TAG_SETUP_LOGS_MESSAGE.GET_ROWS_FOUND_ERROR, error);
        // Handle error
      }
    };

    fetchData();
  }, [id]);
  const setSelectAllRows = isAllRowsSelected => {
    setIsAllRowsSelected(isAllRowsSelected);
    gridApi.forEachNode(node => {
      node.setSelected(isAllRowsSelected);
    });
  };
  const handleChange = event => {
    const checked = event.currentTarget.checked;
    setSelectAllRows(checked);
  };
  const onGridReady = params => {
    columnApi = params.columnApi;
    gridApi = params.api;
    // params.api.autoSizeColumns();
    setIsGridReady(true);
  };
  const headerCheckbox = () => {
    return (
      <Checkbox
        // className="checkbox-style"
        className='header-checkbox'
        classes={{ root: 'custom-checkbox-root' }}
        size='small'
        color='primary'
        onChange={e => handleChange(e)}
      />
    );
  };
  const getActionRenderer = () => {
    return <Box></Box>;
  };

  const IdRenderer = params => {
    return (
      <Link
        to={{
          pathname: `/projects/view-project/${params?.data?.ProjectInvoiceInfo?.id}/Billing/Invoice%20Info`
        }}
        target='_blank'
        rel='noopener noreferrer'
      >
        <Typography style={{ padding: '12px' }} className='text-link' color='primary'>
          {params?.data?.ProjectInvoiceInfo?.job_number}
        </Typography>
      </Link>
    );
  };

  const TitleRenderer = params => {
    return (
      <Link
        to={{
          pathname: `/projects/view-project/${params?.data?.project_id}/Billing/Invoice%20Info`
        }}
        rel='noopener noreferrer'
        target='_blank'
      >
        <Typography style={{ padding: '12px' }} className='text-link' color='primary'>
          {params?.data?.ProjectInvoiceInfo?.job_title}
        </Typography>
      </Link>
    );
  };

  return (
    <div id='view-company' className={'custom-scrollbar pl-3 pt-2 pb-2'}>
      <Box className={isHeaderNav === 'closed' ? 'details-container-close drawer-tab-layout-close' : 'details-container'}>
        <Box className={`scroll-content ${isHeaderNav === 'closed' ? 'details-container-subtab-close' : 'details-container-subtab'}`}>
          {/* <Box className='details-container'>
        <Box className='details-container-subtab scroll-content'> */}
          <Box className='d-flex justify-content-end mb-1 mr-3'>
            <TotalCount rowCount={rowCount} />
          </Box>
          <Box className='list-view'>
            {/* {isGridReady && (
              <ColumnFilter
                columnApi={columnApi}
                defaultColumns={defaultColumns}
                //showFilterCount={showFilterCount}
                //filterModel={filterModel}
              />
            )} */}
            <Box className='ag-theme-alpine text-center mr-3' id='myGrid'>
              <AgGridWrapper
                onGridReady={onGridReady}
                rowData={rowData}
                columnDefs={columnInvoicesDefs()}
                suppressHorizontalScroll={true}
                components={{
                  HeaderCheckbox: headerCheckbox,
                  GetActionRenderer: getActionRenderer,
                  IdRenderer,
                  TitleRenderer
                }}
                rowGroupPanelShow='never'
                sideBarColumnToolPanelParams={{
                  suppressRowGroups: true,
                  suppressValues: true,
                  suppressPivotMode: true
                }}
                showFilters={false}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

ViewInvoices.propTypes = {
  companyData: PropTypes.func,
  isHeaderNav: PropTypes.string
};

export default ViewInvoices;
