import { Box, Grid } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomDropdown from '../../../../../components/common/CustomDropdown';
import CustomNumbericField from '../../../../../components/common/StyledComponents/CustomNumericField';
import { picklistDropDownApis } from '../../../../../services/ApiService';
import { DROP_DOWN_PICKLIST, GET } from '../../../../../services/constantService';
import useApi from '../../../../../utils/Hooks/useApiHook';
import { ProjectDataContext } from '../../Context';

export default function TargetCompensation() {
  const { projectData, handleUniversalState } = useContext(ProjectDataContext);

  const { t } = useTranslation();
  const currencyIcon = useMemo(() => {
    return projectData?.bd_currency_values?.currency_icon ?? '';
  }, [projectData]);

  const { data: currencyTypeResponse } = useApi({
    queryFn: () => {
      return picklistDropDownApis(GET, DROP_DOWN_PICKLIST.CURRENCIES);
    }
  });

  const handleUniversalStateLocally = updatedVal => {
    const event = {
      target: {
        name: 'compensation',
        value: updatedVal
      }
    };
    handleUniversalState(event);
  };

  const [compensation, setCompensation] = useState({
    currency: '',
    min_compensation: '',
    max_compensation: '',
    target_compensation_bonus: '',
    target_compensation_equity: '',
    loaded: false
  });
  const handleChange = (key, value) => {
    compensation[key] = value;
    handleUniversalStateLocally({ ...compensation });
  };

  useEffect(() => {
    const compensationNew = {
      currency: projectData?.bd_currency_values,
      min_compensation: projectData?.min_compensation,
      max_compensation: projectData?.max_compensation,
      target_compensation_bonus: projectData?.target_compensation_bonus,
      target_compensation_equity: projectData?.target_compensation_equity,
      loaded: true
    };
    if (!compensation.loaded) {
      setCompensation(compensationNew);
    }
  }, [projectData]);
  return (
    <Box>
      <Grid container spacing={2} rowSpacing={2}>
        <Grid item xs={4}>
          <CustomDropdown
            options={currencyTypeResponse?.data ?? []}
            label={t('utils.currency')}
            value={compensation?.currency}
            className={'w-100'}
            freeSolo={false}
            onChange={(event, newEvent) => {
              handleChange('currency', newEvent);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomNumbericField
            value={compensation.min_compensation}
            label={t('utils.minCompensation')}
            sx={{
              width: '100%'
            }}
            onChange={value => {
              handleChange('min_compensation', value);
            }}
            prefix={currencyIcon + ' '}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomNumbericField
            value={compensation.max_compensation}
            label={t('utils.maxCompensation')}
            sx={{
              width: '100%'
            }}
            onChange={value => {
              handleChange('max_compensation', value);
            }}
            prefix={currencyIcon + ' '}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomNumbericField
            value={compensation.target_compensation_bonus}
            label={t('utils.bonus')}
            sx={{
              width: '100%'
            }}
            onChange={value => {
              handleChange('target_compensation_bonus', value);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomNumbericField
            value={compensation.target_compensation_equity}
            label={t('reports.candidate_details_information_compensation_equity')}
            sx={{
              width: '100%'
            }}
            onChange={value => {
              handleChange('target_compensation_equity', value);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomNumbericField
            value={+compensation.target_compensation_bonus + +compensation.min_compensation + 0}
            label={t('utils.totalBaseAndBonus')}
            disabled
            sx={{
              width: '100%'
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
