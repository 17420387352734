import { CircularProgress, createFilterOptions, Stack } from '@mui/material';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getMethodWithCancelTokenApi } from '../../../../services/ApiService';
import { IGN_API } from '../../../../services/constantService';
import CustomDropdown from '../../CustomDropdown';

const MultiSelectCompanySelectionDebounce = props => {
  const {
    label = '',
    placeholder = '',
    onChange = () => {},
    defaultValues = [],
    required = false,
    isForAddCompany = false,
    ignoreFiltering = true,
    addAnotherButtonOnChange = () => {
      return -1;
    },
    ...rest
  } = props;

  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const [_, setDefaultOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const filter = createFilterOptions();
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [useInternalState, setUseInternalState] = useState(false);

  const getSearchData = async (searchTerm, initialSearch = false) => {
    try {
      const url = `${IGN_API.picklists}/companies?${initialSearch ? 'initialCompanyFetch=true' : `name=${searchTerm}`}`;
      setLoading(true);
      const response = await getMethodWithCancelTokenApi(url, {}, {});
      const { status, data } = response;

      if (status === 200) {
        const records = data?.data?.map(record => ({ ...record }));
        if (initialSearch) {
          setDefaultOptions(records);
        }
        return records;
      }
      return [];
    } catch (err) {
      console.error('error in getSearchData, error:: ', err);
      return [];
    } finally {
      setLoading(false);
    }
  };

  // Handle search for a single term
  const debounceSearchApiCall = debounce(async (searchTerm, initialSearch = false) => {
    const results = await getSearchData(searchTerm, initialSearch);
    setOptions(prevOptions => {
      const newOptions = [];
      results.forEach(result => {
        if (!prevOptions.some(opt => opt.id === result.id)) {
          newOptions.push(result);
        }
      });
      return [...newOptions, ...prevOptions];
    });
  }, 1000);

  // Load default values on component mount
  useEffect(() => {
    const loadDefaultValues = async () => {
      if (defaultValues && defaultValues.length > 0) {
        setLoading(true);
        const defaultValuesPromises = defaultValues.map(value => getSearchData(typeof value === 'string' ? value : value.name));

        const results = await Promise.all(defaultValuesPromises);
        const flattenedResults = results.flat();

        // Filter exact matches for each default value
        const exactMatches = flattenedResults.filter(result =>
          defaultValues.some(defaultValue => (typeof defaultValue === 'string' && defaultValue === result.name) || defaultValue.name === result.name)
        );

        setOptions(exactMatches);
        setSelectedValues(exactMatches);
        onChange(null, exactMatches); // Notify parent of initial selection
        setLoading(false);
      }
    };

    loadDefaultValues();
  }, [JSON.stringify(defaultValues)]);

  // Handle search input changes
  useEffect(() => {
    if (searchValue && searchValue.length >= 3) {
      debounceSearchApiCall(searchValue);
    }
    return () => {
      debounceSearchApiCall.cancel();
    };
  }, [searchValue]);

  useEffect(() => {
    if (!props?.onInputChange) {
      setUseInternalState(true);
    }
    debounceSearchApiCall(undefined, true);
  }, []);

  return (
    <Stack direction={'row'} position={'relative'}>
      <CustomDropdown
        {...rest}
        options={options}
        label={label}
        placeholder={placeholder}
        onInputChange={(event, newValue) => {
          if (useInternalState) {
            setSearchValue(newValue);
          }
          if (props?.onInputChange) {
            props.onInputChange(event, newValue);
          }
        }}
        onChange={(event, newValue) => {
          if (newValue && (newValue.inputValue || (Array.isArray(newValue) && newValue?.length && newValue[newValue?.length - 1].inputValue))) {
            // window.open(`${window.location.host}/companies/all-companies/add`, '_blank')
          }
          setSelectedValues(newValue);
          onChange(event, newValue);
          event.preventDefault();
        }}
        value={selectedValues}
        required={required}
        addAnotherButton={!isForAddCompany}
        filterOptions={(options, params) => {
          if (ignoreFiltering) {
            return [...options, { inputValue: params.inputValue }];
          }
          const filtered = filter(options, params);
          if (params.inputValue) {
            filtered.push({
              inputValue: params.inputValue
            });
          }
          return filtered;
        }}
        addAnotherButtonText={t('utils.addCompany')}
        isToAddCompany={!isForAddCompany}
        addAnotherButtonOnChange={addAnotherButtonOnChange}
        multiple={true}
        isCheckBox={true}
      />
      <Stack position={'absolute'} right={'56px'} top={0} bottom={0} justifyContent={'center'} alignItems={'center'} sx={{ display: 'flex', width: 40 }}>
        {loading && <CircularProgress size={20} />}
      </Stack>
    </Stack>
  );
};

MultiSelectCompanySelectionDebounce.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  defaultValues: PropTypes.array,
  required: PropTypes.bool,
  isForAddCompany: PropTypes.bool,
  onInputChange: PropTypes.func,
  addAnotherButtonOnChange: PropTypes.func,
  ignoreFiltering: PropTypes.bool
};

export default MultiSelectCompanySelectionDebounce;
