/* eslint-disable */
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import { Box, FormControlLabel, Grid, Radio, RadioGroup, Slider, Stack, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import BrandLogoDark from '../../components/Header/BrandLogoDark';
import CustomButton from '../../components/common/CustomButton';
import Loader from '../../components/common/Loader';
import { getUserSelector } from '../../selectors';
import { ignAthenaDataApi, picklistsDataApis } from '../../services/ApiService';
import { GET, POST, PUT } from '../../services/constantService';
import useApi from '../../utils/Hooks/useApiHook';
import CustomModalWithHeader from '../Commons/CustomModalWithHeader';
import CustomizedDialogs from './PrivacyConfirmPopup';
import './index.scss';

const ProgressComponent = props => {
  const { index, activeQuestion, confirmedQuestions = [], onClick = () => {} } = props;
  const [color, setColor] = useState('');
  const getColorClass = index => {
    if (index == activeQuestion) {
      return 'progress-current';
    } else if (!confirmedQuestions.includes(index)) {
      return 'progress-un-visited';
    } else if (confirmedQuestions.includes(index)) {
      return 'progress-visited';
    }
  };
  useEffect(() => {
    setColor(getColorClass(index));
  }, [props]);
  return (
    <div className={`progress-container ${color} cursor-pointer`} key={index} onClick={() => onClick(index)}>
      <LabelImportantIcon key={index} className={`progress-label ${color}`} />
      <LabelImportantIcon className={`progress-label-next ${color}`} />
    </div>
  );
};

export default function AthenaWrap() {
  const location = useParams();
  const navigate = useNavigate();
  const user = useSelector(getUserSelector);
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const [modalLoader, setModalLoader] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [instructionOpen, setInstructionOpen] = useState(false);
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [confirmedQuestionIndex, setConfirmedQuestionIndex] = useState([]);
  const [showWalkThrough, setShowWalkThrough] = useState(false);
  const [joyStep, setJoyStep] = useState(0);
  const [timeTaken, setTimeTaken] = useState();
  const [qualityOfQuestion, setQualityOfQuestion] = useState();
  const [surveyModal, setSurveyModal] = useState(false);
  const [openPrivacyConfirmationBox, setOpenPrivacyConfirmationBox] = useState(true);
  const [consent, setConsent] = useState(false);

  const walkThroughSteps = [
    {
      target: '.question-slider',
      content: '',
      position: 'center'
    },
    {
      content: (
        <p className='text-left'>
          {`
          The Athena Decision Styles Index will present you with 24 statement
          pairs, with a slider bar between each pair. Please read each set of
          statement pairs and move the slider in the direction that best
          describes you. You MUST move the slider one way or the other, even if
          it's just a little. Don't over-think your response. This isn't
          necessarily your "always" decision style, but instead, it tends to be
          your "go-to" style. After moving the slider to the position you want,
          click "Confirm" to move to the next statement pair. Completing Athena
          usually takes 12 minutes or less, so please allow the time to do it in
          one sitting.`}
        </p>
      ),
      placement: 'center',
      target: 'body'
    },
    {
      target: '.question-slider',
      content: (
        <div className='text-left'>
          <div className='joyride-title font-weight-bold'>Slider</div>
          <p>
            {` The slider bar will turn green when you start to move the slider bar
            to the right of left. Click ‘Confirm’ to submit your answer.`}
          </p>
        </div>
      )
    },
    {
      target: '.progress-bar-target',
      content: (
        <div className='text-left'>
          <div className='joyride-title font-weight-bold'>Progress Bar</div>
          <p>
            {`The slider bar will turn green when you start to move the slider bar
            to the right of left. Click ‘Confirm’ to submit your answer.`}
          </p>
        </div>
      )
    },
    {
      target: '.athena-finish-button',
      content: (
        <>
          <div className='joyride-title'>Finish</div>
          <p>
            {`After you have completed Athena, your progress bar will be entirely
            TEAL To Finish, click the 'Finish' button. Note that you must first
            respond to ALL statement`}
          </p>
        </>
      ),
      placement: 'left'
    }
  ];

  const marks = [
    {
      value: 0,
      label: '50'
    },
    {
      value: 25,
      label: '25'
    },
    {
      value: 50,
      label: '0'
    },
    {
      value: 75,
      label: '25'
    },
    {
      value: 100,
      label: '50'
    }
  ];

  const { data: TimeTakenResponse } = useApi({
    queryFn: () => {
      return picklistsDataApis(GET, 'TIME_TAKEN_ATHENA');
    }
  });

  const { data: QualityOfChoiceResponse } = useApi({
    queryFn: () => {
      return picklistsDataApis(GET, 'QUALITY_OF_CHOICE_ATHENA');
    }
  });

  const handleNext = () => {
    if (activeQuestion + 1 < questions.length) {
      setActiveQuestion(activeQuestion + 1);
    }
  };

  const handleBack = () => {
    setActiveQuestion(activeQuestion - 1);
  };

  const fetchQuestion = async () => {
    try {
      setLoader(true);
      const res = await ignAthenaDataApi(GET, '', '', 'assessment-questions');
      const sortedData = res?.data?.sort((a, b) => (a.sequence > b.sequence ? 1 : b.sequence > a.sequence ? -1 : 0));
      setQuestions(sortedData);
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setLoader(false);
    }
  };

  const handleQuestionSkip = async question => {
    try {
      setLoader(true);
      handleNext();
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setLoader(false);
    }
  };

  const handleQuestionConfirm = async (question, index) => {
    try {
      setLoader(true);
      console.log({ question });
      if (question?.assessment_score_id) {
        const payload = {
          id: question.assessment_score_id,
          score: question?.score ?? 50
        };
        const res = await ignAthenaDataApi(PUT, '', payload, `update-assessment-score`);
        console.log({ res });
      } else {
        const payload = {
          assessment_id: location?.assessment,
          assessment_question_id: question?.id,
          score: question?.score ?? 50,
          sequence: question?.sequence
        };
        const res = await ignAthenaDataApi(POST, '', payload, 'create-assessment-scores');
        console.log({ res });
        questions[index]['assessment_score_id'] = res?.data?.id;
      }
      if (!confirmedQuestionIndex.includes(index)) {
        setConfirmedQuestionIndex([...confirmedQuestionIndex, index]);
      }
      handleNext();
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setLoader(false);
    }
  };

  const handleSliderChange = (index, value) => {
    questions[index]['score'] = value;
    setQuestions(questions.slice());
  };

  const handleOnClickProgressBar = index => {
    setActiveQuestion(index);
  };
  useEffect(() => {
    fetchQuestion();
  }, []);

  const handleJoyrideCallback = data => {
    const { action, index, status, type } = data;
    console.log({ handleJoyrideCallback: data });
    if (index == 0) {
      setJoyStep(1);
    }
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      setJoyStep(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setShowWalkThrough(false);
    }
  };

  const handleSubmitSurvey = async () => {
    try {
      setModalLoader(true);
      const assessmentId = location?.assessment;
      const coordinatePayload = {
        project_id: '',
        assessment_ids: [assessmentId],
        contact_ids: []
      };
      const coordinateRes = await ignAthenaDataApi(POST, '', coordinatePayload, 'candidate-coordinates');

      console.log({ coordinateRes });
      const coordinates = coordinateRes?.data?.data[0]?.coordinates;
      if (coordinates) {
        const payload = {
          id: assessmentId,
          time_taken: timeTaken,
          quality_of_choice: qualityOfQuestion,
          status: 'athena_complete',
          completed_at: new Date(),
          x_coordinate: coordinates.x,
          y_coordinate: coordinates.y
        };

        console.log({ payload });
        await ignAthenaDataApi(PUT, '', payload, `update-assessment`);
      }
      const emailPayload = {
        assessment_id: assessmentId
      };
      const emailRes = await ignAthenaDataApi(POST, '', emailPayload, `complete`);
      enqueueSnackbar(emailRes.data);
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setModalLoader(false);
      setSurveyModal(false);
      enqueueSnackbar('Your Assessment is submitted successfully, you can exit the window now.', 'success');
    }
  };

  return (
    <>
      <Loader show={loader} />
      <Box>
        <Joyride
          callback={handleJoyrideCallback}
          stepIndex={joyStep}
          steps={walkThroughSteps}
          run={showWalkThrough}
          continuous={true}
          scrollToFirstStep={true}
          showProgress={false}
          styles={{
            options: {
              zIndex: 10000
            }
          }}
          autostart
        />
        <div className='athena-header d-flex w-100 justify-content-between p-3 align-items-center'>
          <BrandLogoDark />
          <div className='d-flex align-items-center header-text-white'>
            <ContactSupportIcon onClick={() => setInstructionOpen(!instructionOpen)} />
            {location?.name ? <div className='fs-14 ml-1'>{location?.name}</div> : <div className='fs-14 ml-1'>{user?.name}</div>}
          </div>
        </div>

        <div className='athena-container w-100 p-3'>
          <div className='p-2 w-100 fs-12'>{location?.name ? <div className='fs-14 ml-1'>{`Viewing Athena as ${location?.name}`}</div> : ''}</div>
          {instructionOpen && (
            <>
              <div className='d-flex justify-content-left w-100'>
                <CustomButton buttonText={'< Back'} variant={'text'} type='tertiary' onClick={() => setInstructionOpen(falses)} />
              </div>
              <div className='d-flex justify-content-center w-100'>
                <div className='fs-14 m-auto font-weight-bold'>Slider</div>
              </div>

              <Grid container className='w-100'>
                <Grid item xs={4} className='d-flex flex-column p-3 w-100 align-items-center justify-content-around'>
                  <div className='d-flex flex-column border p-4 w-100 align-items-center justify-content-around athena-question-box rounded shadow'>
                    <Slider
                      // aria-label="Always visible"
                      defaultValue={50}
                      step={25}
                      marks={marks}
                      track={false}
                      className='question-slider'
                      // valueLabelDisplay="on"
                    />

                    <div className='d-flex flex-column justify-content-center align-items-center'>
                      <CustomButton buttonText={'Confirm'} />
                      <CustomButton buttonText={'skip'} type={'question-skip mt-2'} variant={'text'} />
                    </div>
                  </div>
                  <div className='fs-12 mt-4 font-weight-bold'>Non Activated/Skip</div>
                </Grid>
                <Grid item xs={4} className='d-flex flex-column p-3 w-100 align-items-center justify-content-around'>
                  <div className='d-flex flex-column border p-4 w-100 align-items-center justify-content-around athena-question-box rounded shadow'>
                    <Slider
                      // aria-label="Always visible"
                      defaultValue={50}
                      step={25}
                      marks={marks}
                      track={false}
                      className='question-slider'
                      // valueLabelDisplay="on"
                    />

                    <div className='d-flex flex-column justify-content-center align-items-center'>
                      <CustomButton buttonText={'Confirm'} />
                      <CustomButton buttonText={'skip'} type={'question-skip mt-2'} variant={'text'} />
                    </div>
                  </div>
                  <div className='fs-12 mt-4 font-weight-bold'>Active</div>
                </Grid>
                <Grid item xs={4} className='d-flex flex-column p-3 w-100 align-items-center justify-content-around'>
                  <div className='d-flex flex-column border p-4 w-100 align-items-center justify-content-around athena-question-box rounded shadow'>
                    <Slider
                      // aria-label="Always visible"
                      defaultValue={75}
                      step={25}
                      marks={marks}
                      track={false}
                      className='question-slider'
                      // valueLabelDisplay="on"
                    />

                    <div className='d-flex flex-column justify-content-center align-items-center'>
                      <CustomButton buttonText={'Confirm'} />
                      <CustomButton buttonText={'skip'} type={'question-skip mt-2'} variant={'text'} />
                    </div>
                  </div>
                  <div className='fs-12 mt-4 font-weight-bold'>Selected</div>
                </Grid>
                <Grid item xs={4}>
                  <div className='fs-12 p-3'>
                    {`To skip a pair of statements for the moment, click the "Skip"
                  button. This creates a TEAL box on the progress bar showing
                  that you had skipped that pair. You will need to need to
                  return to complete any skipped pairs by clicking on the red
                  boxes in the Progress bar. You need to respond to all
                  statement pairs (thus no magenta boxes) before you can finish
                  Athena.`}
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className='fs-12 p-3'>{`The slider bar will turn green when you start to move the slider bar to either the right or left-hand box.`}</div>
                </Grid>
                <Grid item xs={4}>
                  <div className='fs-12 p-3'>{`Once you are ready to submit your response, click on the Confirm button underneath the slider bar and you will be directed to the next question`}</div>
                </Grid>
                <Grid item xs={4} className={'d-flex w-100 justify-content-center p-3'}>
                  {questions && questions.map((data, index) => <ProgressComponent index={index} key={index} activeQuestion={6} confirmedQuestions={confirmedQuestionIndex} />)}
                </Grid>
                <Grid item xs={8} className='p-3 '>
                  <div className='fs-14 font-weight-bold'>Progress bar</div>
                  <div className='fs-12 mt-1'>
                    {`The progress bar gives you a visual of the progress towards completion of this Online Structured Interview. Any "teal boxes" that appear in the progress bar represent questions that were skipped. You will need to come back to complete any skipped questions by clicking on the peach box in the Progress bar to take you to the question that needs to be completed.`}
                  </div>
                </Grid>
                <Grid item xs={2} className='p-3 d-flex w-100 justify-content-center'>
                  <CustomButton buttonText={'Finish'} type={'athena-finish-button pl-2 pr-2'} />
                </Grid>
                <Grid item xs={10} className='p-3'>
                  <div className='fs-14 font-weight-bold'>Finish</div>
                  <div className='fs-12 mt-1'>
                    {`After you have completed Athena, your progress bar will be entirely magenta. To Finish, click the "Finish" button. Note that you must first respond to ALL statement pairs for your results to be complete`}
                  </div>
                </Grid>
              </Grid>
            </>
          )}

          {!instructionOpen && (
            <div className='w-100 p-2 mh-100'>
              <div className='progress-bar-target d-flex w-100 justify-content-center mt-3'>
                {questions.map((data, index) => (
                  <ProgressComponent
                    index={index}
                    key={index}
                    activeQuestion={activeQuestion}
                    confirmedQuestions={confirmedQuestionIndex}
                    onClick={index => {
                      handleOnClickProgressBar(index);
                    }}
                  />
                ))}
              </div>
              <Grid container className='question-grid mt-4'>
                <Grid item xs={1} className='d-flex w-100 align-items-center justify-content-center '>
                  {activeQuestion > 0 && (
                    <div className='border border-dark rounded-circle shadow'>
                      <ArrowBackIosRoundedIcon className='fs-24 m-2' onClick={handleBack} />
                    </div>
                  )}
                </Grid>
                <Grid container item xs={10} className='shadow'>
                  <>
                    <Grid item xs={4} className='border p-3 w-100 question rounded-left d-flex align-items-center'>
                      <div
                        className='question-font'
                        dangerouslySetInnerHTML={{
                          __html: questions[activeQuestion]?.left_question || '-'
                        }}
                      ></div>
                    </Grid>
                    <Grid item xs={4} className='d-flex flex-column border p-3 w-100 align-items-center justify-content-around athena-question-box'>
                      <div className='fs-bold fs-14 text-center'>I seek out environments in which people are expected to:</div>
                      <Slider
                        // aria-label="Custom marks"
                        defaultValue={50}
                        step={1}
                        value={questions[activeQuestion]?.score ?? 50}
                        marks={marks}
                        track={false}
                        className='question-slider'
                        onChange={e => {
                          handleSliderChange(activeQuestion, e.target.value);
                        }}
                        // valueLabelDisplay="on"
                      />

                      <div className='d-flex flex-column justify-content-center align-items-center'>
                        <CustomButton
                          buttonText={'Confirm'}
                          onClick={() => {
                            handleQuestionConfirm(questions && questions[activeQuestion] ? questions[activeQuestion] : [], activeQuestion);
                          }}
                          type={'athena-confirm-button'}
                        />
                        <CustomButton
                          buttonText={'skip'}
                          type={'question-skip mt-2'}
                          variant={'text'}
                          onClick={() => {
                            handleQuestionSkip(questions && questions[activeQuestion] ? questions[activeQuestion] : []);
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={4} className='border p-3 question rounded-right d-flex align-items-center'>
                      <div
                        className='question-font'
                        dangerouslySetInnerHTML={{
                          __html: questions[activeQuestion]?.right_question || '-'
                        }}
                      ></div>
                    </Grid>
                  </>
                </Grid>
                <Grid item xs={1} className='d-flex w-100 align-items-center justify-content-center '>
                  {activeQuestion < questions.length - 1 && (
                    <div className='border border-dark rounded-circle shadow'>
                      <ArrowForwardIosRoundedIcon className='fs-24 m-2' onClick={handleNext} />
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} className={'d-flex flex-column justify-content-center align-items-center'}>
                  {confirmedQuestionIndex.length === questions.length || showWalkThrough ? (
                    <CustomButton size='large' buttonText={'Finish'} onClick={() => setSurveyModal(true)} type={'athena-finish-button pl-2 pr-2'} />
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </div>
          )}
        </div>
        <CustomModalWithHeader closeIcon={true} onClose={() => setSurveyModal(false)} isOpen={surveyModal} label={'Structured Interview Survey'}>
          <div>
            <Loader show={modalLoader} />
            <p className='fs-16 athena-survey-bg-p p-3 rounded'>
              We Thank you for taking the time to complete our Online Structured Interview, You have successfully completed the process and your responses have been recorded. You will be contacted by
              your Kingsley Gate team with your results
            </p>
            <p className='fs-14 mt-2'>Please Give your Feedback for the online structured interview</p>
            <p className='mt-4 fs-16 fw-bolder'>Time Taken to complete the online interview</p>
            <RadioGroup row className='justify-content-between' aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group'>
              {TimeTakenResponse &&
                TimeTakenResponse?.data?.map(data => (
                  <FormControlLabel
                    value={data?.field_value}
                    control={
                      <Radio
                        onClick={() => {
                          setTimeTaken(data?.field_value);
                        }}
                      />
                    }
                    label={data?.long_desc}
                  />
                ))}
            </RadioGroup>
            <p className='mt-2 fs-16 fw-bold'>Quality of Choices</p>
            <RadioGroup row className='justify-content-between' aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group'>
              {QualityOfChoiceResponse &&
                QualityOfChoiceResponse?.data?.map(data => (
                  <FormControlLabel
                    value={data?.field_value}
                    control={
                      <Radio
                        onClick={() => {
                          setQualityOfQuestion(data?.field_value);
                        }}
                      />
                    }
                    label={data?.long_desc}
                  />
                ))}
            </RadioGroup>
            <Stack>
              <Typography value={'sample text'} spacing={1} />
            </Stack>
            <div className='w-100 d-flex justify-content-end'>
              <CustomButton size={'large'} buttonText={'Submit'} type={'athena-confirm-button'} onClick={handleSubmitSurvey} />
            </div>
          </div>
        </CustomModalWithHeader>
        {openPrivacyConfirmationBox && (
          <CustomizedDialogs
            openPrivacyConfirmationBox={openPrivacyConfirmationBox}
            setOpenPrivacyConfirmationBox={setOpenPrivacyConfirmationBox}
            walkthrow={setShowWalkThrough}
            consent={consent}
            setConsent={setConsent}
          />
        )}
      </Box>
    </>
  );
}

ProgressComponent.propTypes = {
  index: PropTypes.number,
  activeQuestion: PropTypes.number,
  confirmedQuestions: PropTypes.array,
  onClick: PropTypes.func
};
