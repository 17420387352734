import { Box, List, ListItem } from '@mui/material';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { addFromLinkedinApi, genericQuickSearchApi } from '../../services/ApiService';
import ResumeParser from '../common/ResumeParser';
import AlertDialog from './AlertDialog';
import AddContactDrawerAccordion from './component/AddContactDrawerAccordion';
import AddContactDrawerHeader from './component/AddContactDrawerHeader';
import AddContactDrawerSectionEight from './component/AddContactDrawerSectionEight';
import AddContactDrawerSectionEleven from './component/AddContactDrawerSectionEleven';
import AddContactDrawerSectionFive from './component/AddContactDrawerSectionFive';
import AddContactDrawerSectionFour from './component/AddContactDrawerSectionFour';
import AddContactDrawerSectionNine from './component/AddContactDrawerSectionNine';
import AddContactDrawerSectionOne from './component/AddContactDrawerSectionOne';
import AddContactDrawerSectionSeven from './component/AddContactDrawerSectionSeven';
import AddContactDrawerSectionSix from './component/AddContactDrawerSectionSix';
import AddContactDrawerSectionTen from './component/AddContactDrawerSectionTen';
import AddContactDrawerSectionThree from './component/AddContactDrawerSectionThree';
import AddContactDrawerSectionTwelve from './component/AddContactDrawerSectionTwelve';
import AddContactDrawerSectionTwo from './component/AddContactDrawerSectionTwo';
import AddContactDrawerSubHeader from './component/AddContactDrawerSubHeader';
function ContactDrawerUI({
  contactData,
  recordTypeDropdownOptions,
  projectList,
  projectStageList,
  countryList,
  stateList,
  companyNameList,
  levelOfEducationList,
  certificationTypeList,
  phoneTypeList,
  emailTypeList,
  addressTypeList,
  languageList,
  levelOfProficiencyList,
  jobFunctionList,
  industryList,
  nationalityList,
  getDropdownData,
  RelationTypeList,
  loadingDropdownData,
  saveContact,
  toggleDrawer,
  CustomHeader,
  includeHeader,
  includeSubHeader,
  customHeaderHeight = 0,
  sectionVisibility = {
    sectionOne: true,
    sectionTwo: true,
    sectionThree: true,
    sectionFour: true,
    sectionFive: true,
    sectionSix: true,
    sectionSeven: true,
    sectionEight: true,
    sectionNine: true,
    sectionTen: true,
    sectionEleven: true,
    sectionTwelve: true
  },
  duplicateContact,
  setDuplicateContact,
  type = 'create',
  defaultValues = {},
  globalLoading = false,
  callback = () => {}
}) {
  const [expand, setExpand] = useState({
    sectionOne: true,
    sectionTwo: true,
    sectionThree: true,
    sectionFour: true,
    sectionFive: true,
    sectionSix: true,
    sectionSeven: true,
    sectionEight: true,
    sectionNine: true,
    sectionTen: true,
    sectionEleven: true,
    sectionTwelve: true
  });
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [newProfessionalExperienceOpen, setNewProfessionalExperienceOpen] = useState(!type === 'edit');
  const [newEducationOpen, setNewEducationOpen] = useState(!type === 'edit');
  const [newCertificateAndLicensesOpen, setNewCertificateAndLicensesOpen] = useState(!type === 'edit');
  const [newRelationshipOpen, setNewRelationshipOpen] = useState(!type === 'edit');
  const [newLanguageOpen, setNewLanguageOpen] = useState(!type === 'edit');
  const [openCreateContactFromResume, setOpenCreateContactFromResume] = useState(false);
  const [linkedinURL, setLinkedinURL] = useState('');
  const { t } = useTranslation();
  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors }
  } = useForm();
  useEffect(() => {
    register('first_name', { required: true });
    register('last_name', { required: true });
    register('avatar', { required: false });
    register('email', { required: true });
    register('phone', { required: true });
    register('linkedin_url', { required: false });
    register('linkedin_username', { required: false });
    register('record_type', { required: false });
    register('record_source', { required: false });
    register('project_name', { required: false });
    register('project_stage', { required: false });
    register('contact_work_experience', { required: false });
    register('education', { required: false });
    register('certifications_and_licenses', { required: false }); // need to separate before saving - contact_certificate
    register('contact_languages', { required: false });
    register('contact_phones', { required: false });
    register('contact_emails', { required: false });
    register('contact_address', { required: false }); // state_id country_id
    register('contact_job_functions', { required: false });
    register('contact_industries', { required: false });
    register('contact_relationship_type', { required: false });
    register('highlights', { required: false });
    register('birth_date', { required: false });
    register('gender', { required: false });
    register('nationality', { required: false });
    register('additional_last_name', { required: false });
    register('pronouns', { required: false });
    register('is_confidential', { required: false });
    register('linkedin_data', { required: false });
    register('linkedin_avatar', { required: false });
  }, []);
  const props = {
    register,
    unregister,
    handleSubmit,
    setValue,
    getValues,
    watch,
    errors,
    uploadTranslateTitle: t('addContactDrawer.upload'),
    setNewProfessionalExperienceOpen,
    newProfessionalExperienceOpen,
    setNewEducationOpen,
    newEducationOpen,
    newCertificateAndLicensesOpen,
    setNewCertificateAndLicensesOpen,
    newRelationshipOpen,
    setNewRelationshipOpen,
    newLanguageOpen,
    setNewLanguageOpen,
    recordTypeDropdownOptions,
    projectList,
    projectStageList,
    countryList,
    stateList,
    companyNameList,
    levelOfEducationList,
    certificationTypeList,
    phoneTypeList,
    emailTypeList,
    addressTypeList,
    languageList,
    levelOfProficiencyList,
    jobFunctionList,
    industryList,
    nationalityList,
    getDropdownData,
    RelationTypeList,
    loadingDropdownData,
    type,
    globalLoading
  };

  const autoFillFieldsFromResumeParser = async e => {
    toggleDrawer(false);
  };
  const getStateData = async countryName => {
    if (!countryName) return;
    const res = await getDropdownData(`/countries/states?countryName=${countryName}`);
    return res || [];
  };
  const firstLetterUpperCase = str => {
    if (!str) return '';
    const a = str?.charAt(0)?.toUpperCase() + str?.slice(1);
    if (!a) return '';
    return a;
  };
  const createBlobFromUrl = async url => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error('Error fetching image:', error);
      return null;
    }
  };
  const addFromLinkedin = async e => {
    try {
      let data = {
        linkedin_url: e
      };
      let response = await addFromLinkedinApi(data);

      if (response?.status === 400) {
        setDuplicateContact({
          isDuplicate: true,
          string: response?.message,
          data: response?.data
        });
        return;
      }
      if (response?.message && response?.status !== 200) {
        enqueueSnackbar(`Error getting profile data: ${response?.message || ''}`, { variant: 'error' });
        return;
      }
      const res = response?.data;
      setValue('linkedin_data', res?.source_data || '');
      setValue('linkedin_url', res?.linkedIn_url || '');
      setValue('record_source', res?.source || '');
      // setValue('linkedin_username', res?.['linkedIn_url']?.split('/')[2] || '');
      const name = res?.data?.['Candidate Name']?.split(' ') || '';
      setValue('first_name', name?.slice(0, -1)?.join(' ') || '');
      setValue('last_name', name?.[name?.length - 1] || '');
      setValue('highlights', `${res?.data?.['Title'] || res?.data?.['Summary']}`);
      if (res?.data?.['ImageUrl']) {
        setValue('linkedin_avatar', await createBlobFromUrl(res?.data?.['ImageUrl'] || ''));
        setValue('avatar', '');
      }
      const findEducation = res?.data?.['Education']?.map((education, index) => ({
        id: index + 1,
        degree_name: firstLetterUpperCase(education?.major),
        school_name: firstLetterUpperCase(education?.institute),
        country: '',
        start_date: dayjs(education?.start_year).isValid() ? dayjs(education?.start_year) : null,
        end_date: dayjs(education?.end_year).isValid() ? dayjs(education?.end_year) : null,
        is_present: dayjs(education?.end_year).isAfter(dayjs()) ? true : false,
        education_level: education
      }));
      setValue('education', findEducation);
      const findCertification = res?.source_data?.['certifications']?.map((certification, index) => ({
        id: index + 1,
        type: {
          name: 'Certificate',
          id: 'certificate'
        },

        title: certification?.title,
        expires: certification?.date_to_year
      }));
      setValue('certifications_and_licenses', findCertification);
      setNewCertificateAndLicensesOpen(false);
      const findCompany = async companyName => {
        const company = await genericQuickSearchApi({
          modelName: 'ign_company',
          size: 1,
          from: 0,
          and: [
            {
              'name.keyword': companyName
            }
          ],
          or: [],
          fields: ['id', 'name']
        });

        if (company?.data?.length > 0) {
          return {
            id: company?.data[0]?.id || '',
            name: company?.data[0]?.name || '',
            exists: true
          };
        }
        return {
          id: null,
          name: companyName || '',
          exists: false
        };
      };
      const findExperience = await Promise.all(
        res?.data?.['Experience']?.map(async (experience, index) => ({
          description: experience?.summary,
          id: index + 1,
          title: firstLetterUpperCase(experience?.title),
          company: await findCompany(experience?.company),
          country: countryList.find(country => country?.name === experience?.GeoLocation?.country) || {
            id: null,
            name: experience?.GeoLocation?.country || '',
            new: true
          },
          state: getStateData(experience?.GeoLocation?.state)?.[0] || {
            id: null,
            name: experience?.GeoLocation?.state || '',
            new: true
          },
          city: experience?.GeoLocation?.city || '',
          start_date: dayjs(experience?.date_from).isValid() ? dayjs(experience?.date_from) : null,
          end_date: dayjs(experience?.date_to).isValid() ? dayjs(experience?.date_to) : null,
          is_present: dayjs(experience?.date_to).isAfter(dayjs()) ? true : false,
          board_start_date: null,
          board_end_date: null,
          is_board_member: false,
          board_is_present: false,
          board_committee: null
        }))
      );

      setValue('contact_work_experience', findExperience);
      setNewProfessionalExperienceOpen(false);
      const languageProficiency = {
        'Full professional proficiency': 'Fluent',
        'Native or bilingual proficiency': 'Native',
        other: 'Intermediate',
        Elementary: 'Basic'
      };
      const findLanguage = res?.source_data?.['languages']?.map((contact_languages, index) => ({
        id: index + 1,
        contact_languages: languageList.find(item => item.name === contact_languages?.language) || {
          id: null,
          name: contact_languages?.language || '',
          new: true
        },
        level_of_expertise: {
          name: languageProficiency[contact_languages?.proficiency] || 'Intermediate',
          id: languageProficiency[contact_languages?.proficiency]?.toLowerCase() || 'intermediate'
        }
      }));
      setValue('contact_languages', findLanguage);
      const contactAddress = [
        {
          id: 1,
          address_type: addressTypeList?.[0] || {
            id: 'home',
            name: 'Home',
            new: true
          },
          address_line: '',
          city: res?.data?.['GeoLocation']?.city || '',
          country: countryList.find(country => country?.name === res?.data?.['GeoLocation']?.country) || {
            id: null,
            name: res?.data?.['GeoLocation']?.country || '',
            new: true
          },
          state: getStateData(res?.data?.['GeoLocation']?.state)?.[0] || {
            id: null,
            name: res?.data?.['GeoLocation']?.state || '',
            new: true
          },
          zip_code: res?.data?.['GeoLocation']?.zip_code || '',

          is_primary: true
        }
      ];

      setValue('contact_address', contactAddress);
      console.log('education', findEducation, res?.source_data, res?.data);
      // ---------------------------------------------------------------------

      // ---------------------------------------------------------------------
      // const createBlobFromUrl = async url => {
      //   const response = await fetch(url, {});
      //   const blob = await response.blob();
      //   return new File([blob], 'avatar.jpg', { type: 'image/jpeg' });
      // };
      // setValue('avatar', (await createBlobFromUrl(res?.data?.data?.ImageUrl || res?.source_data?.['picture_url'])) || '');
    } catch (error) {
      enqueueSnackbar(`Error getting profile data: ${error?.message || ''}`, { variant: 'error' });
    }
  };
  const expandAll = () => {
    setExpand({
      ...expand,
      sectionOne: true,
      sectionTwo: true,
      sectionThree: true,
      sectionFour: true,
      sectionFive: true,
      sectionSix: true,
      sectionSeven: true,
      sectionEight: true,
      sectionNine: true,
      sectionTen: true,
      sectionEleven: true,
      sectionTwelve: true
    });
  };
  const collapseAll = () => {
    setExpand({
      ...expand,
      sectionOne: false,
      sectionTwo: false,
      sectionThree: false,
      sectionFour: false,
      sectionFive: false,
      sectionSix: false,
      sectionSeven: false,
      sectionEight: false,
      sectionNine: false,
      sectionTen: false,
      sectionEleven: false,
      sectionTwelve: false
    });
  };
  const validateCheck = values => {
    const checkWorkExperience = values?.contact_work_experience?.some(item => item?.edit);
    const checkEducation = values?.education?.some(item => item?.edit);
    const checkCertificate = values?.certifications_and_licenses?.length > 0 ? newCertificateAndLicensesOpen : false;
    const checkLanguage = values?.contact_languages?.length > 0 ? newLanguageOpen : false;
    const checkPhone = values?.contact_phones?.some(item => item?.edit);
    const checkEmail = values?.contact_emails?.some(item => item?.edit);
    const checkAddress = values?.contact_address?.some(item => item?.edit);
    const checkRelationship = values?.contact_relationship_type?.length > 0 ? newRelationshipOpen : false;
    return !(checkWorkExperience || checkEducation || checkCertificate || checkLanguage || checkPhone || checkEmail || checkAddress || checkRelationship);
  };
  const saveAPI = async () => {
    try {
      setOpenAlertDialog(false);
      console.log('getValues()', getValues());
      const values = getValues();
      await saveContact(values).then(response => {
        console.log('response', response);
        const val = getValues();
        console.log('val', val);
        if (response) {
          setLinkedinURL('');
          toggleDrawer(false);
          resetForm();
        }
        throw new Error('Error saving contact');
      });
    } catch (error) {
      console.log('error', error);
    }
  };
  const onSave = async () => {
    try {
      if (!validateCheck(getValues())) {
        setOpenAlertDialog(true);
        return;
      }
      await saveAPI();
      callback?.();
    } catch (error) {
      console.log('error', error);
    }
  };
  const resetForm = () => {
    setValue('first_name', '');
    setValue('last_name', '');
    setValue('avatar', '');
    setValue('email', '');
    setValue('phone', '');
    setValue('linkedin_url', '');
    setValue('linkedin_username', '');
    setValue('record_type', []);
    setValue('record_source', '');
    setValue('project_name', null);
    setValue('project_stage', null);
    setValue('contact_work_experience', []);
    setValue('education', []);
    setValue('certifications_and_licenses', []); // need to separate before saving - contact_certificate
    setValue('contact_languages', []);
    setValue('contact_phones', []);
    setValue('contact_emails', []);
    setValue('contact_industries', []); // state_id country_id
    setValue('contact_job_functions', []);
    setValue('contact_industries', []);
    setValue('contact_relationship_type', []);
    setValue('highlights', '');
    setValue('birth_date', null);
    setValue('gender', '');
    setValue('nationality', '');
    setValue('additional_last_name', '');
    setValue('pronouns', '');
  };
  const onSaveAndAddAnother = async () => {
    try {
      if (!validateCheck(getValues())) {
        setOpenAlertDialog(true);
        return;
      }
      await saveAPI();
    } catch (error) {
      console.log('error', error);
    }
  };
  const handleResumeParseClose = () => {
    setOpenCreateContactFromResume(false);
  };
  const sections = [
    {
      title: t('addContactDrawer.sectionOne'),
      expand: expand.sectionOne,
      visible: sectionVisibility?.sectionOne,
      setExpand: () => setExpand({ ...expand, sectionOne: !expand.sectionOne }),
      children: props => <AddContactDrawerSectionOne {...props} />
    },
    {
      title: t('addContactDrawer.sectionTwo'),
      expand: expand.sectionTwo,
      visible: sectionVisibility?.sectionTwo,
      setExpand: () => setExpand({ ...expand, sectionTwo: !expand.sectionTwo }),
      children: props => <AddContactDrawerSectionTwo {...props} />
    },
    {
      title: t('addContactDrawer.sectionThree'),
      addTitle: !newProfessionalExperienceOpen && expand.sectionThree ? t('addContactDrawer.add') : null,
      onClickAdd: () => {
        setNewProfessionalExperienceOpen(true);
        setExpand({ ...expand, sectionThree: true });
      },
      expand: expand.sectionThree,
      visible: sectionVisibility?.sectionThree,
      setExpand: () => setExpand({ ...expand, sectionThree: !expand.sectionThree }),
      children: props => <AddContactDrawerSectionThree {...props} />
    },
    {
      title: t('addContactDrawer.sectionFour'),
      expand: expand.sectionFour,
      addTitle: !newEducationOpen && expand.sectionFour ? t('addContactDrawer.add') : null,
      onClickAdd: () => {
        setNewEducationOpen(true);
        setExpand({ ...expand, sectionFour: true });
      },
      visible: sectionVisibility?.sectionFour,
      setExpand: () => setExpand({ ...expand, sectionFour: !expand.sectionFour }),
      children: props => <AddContactDrawerSectionFour {...props} />
    },
    {
      title: t('addContactDrawer.sectionFive'),
      expand: expand.sectionFive,
      addTitle: !newCertificateAndLicensesOpen && expand.sectionFive ? t('addContactDrawer.add') : null,
      onClickAdd: () => {
        setNewCertificateAndLicensesOpen(true);
        setExpand({ ...expand, sectionFive: true });
      },
      setExpand: () => setExpand({ ...expand, sectionFive: !expand.sectionFive }),
      visible: sectionVisibility?.sectionFive,
      children: props => <AddContactDrawerSectionFive {...props} />
    },
    {
      title: t('addContactDrawer.sectionSix'),
      expand: expand.sectionSix,
      setExpand: () => setExpand({ ...expand, sectionSix: !expand.sectionSix }),
      visible: sectionVisibility?.sectionSix,
      children: props => <AddContactDrawerSectionSix {...props} />
    },
    {
      title: t('addContactDrawer.sectionSeven'),
      expand: expand.sectionSeven,
      addTitle: !newLanguageOpen && expand.sectionSeven ? t('addContactDrawer.add') : null,
      onClickAdd: () => {
        setNewLanguageOpen(true);
        setExpand({ ...expand, sectionSeven: true });
      },
      setExpand: () => setExpand({ ...expand, sectionSeven: !expand.sectionSeven }),
      children: props => <AddContactDrawerSectionSeven {...props} />,
      visible: sectionVisibility?.sectionSeven
    },
    {
      title: t('addContactDrawer.sectionEight'),
      expand: expand.sectionEight,
      setExpand: () => setExpand({ ...expand, sectionEight: !expand.sectionEight }),
      children: props => <AddContactDrawerSectionEight {...props} />,
      visible: sectionVisibility?.sectionEight
    },
    {
      title: t('addContactDrawer.sectionNine'),
      expand: expand.sectionNine,
      setExpand: () => setExpand({ ...expand, sectionNine: !expand.sectionNine }),
      children: props => <AddContactDrawerSectionNine {...props} />,
      visible: sectionVisibility?.sectionNine
    },
    {
      title: t('addContactDrawer.sectionTen'),
      expand: expand.sectionTen,
      addTitle: !newRelationshipOpen && expand.sectionTen ? t('addContactDrawer.add') : null,
      onClickAdd: () => {
        setNewRelationshipOpen(true);
        setExpand({ ...expand, sectionTen: true });
      },
      setExpand: () => setExpand({ ...expand, sectionTen: !expand.sectionTen }),
      children: props => <AddContactDrawerSectionTen {...props} />,
      visible: sectionVisibility?.sectionTen
    },
    {
      title: t('addContactDrawer.sectionEleven'),
      expand: expand.sectionEleven,

      setExpand: () => setExpand({ ...expand, sectionEleven: !expand.sectionEleven }),
      children: props => <AddContactDrawerSectionEleven {...props} />,
      visible: sectionVisibility?.sectionEleven
    },
    {
      title: t('addContactDrawer.sectionTwelve'),
      expand: expand.sectionTwelve,
      setExpand: () => setExpand({ ...expand, sectionTwelve: !expand.sectionTwelve }),
      children: props => <AddContactDrawerSectionTwelve {...props} />,
      visible: sectionVisibility?.sectionTwelve
    }
  ];
  useEffect(() => {
    if (type === 'edit') {
      for (const key in contactData) {
        if (contactData[key]) {
          setValue(key, contactData[key]);
        }
      }
    }
    if (defaultValues) {
      for (const key in defaultValues) {
        if (defaultValues[key]) {
          setValue(key, defaultValues[key]);
        }
      }
    }
  }, [contactData]);
  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
        height: '100%',
        overflowY: 'scroll',
        bgcolor: 'background.main',
        borderRadius: '0 0 5px 5px',
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        // smooth scroll
        scrollBehavior: 'smooth'
      }}
      id='scrollable-drawer'
      role='presentation'
    >
      <List disablePadding sx={{ bgcolor: '#e9e9ed', width: '100%', minHeight: '100%' }}>
        <ListItem
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
            height: customHeaderHeight || (duplicateContact?.isDuplicate ? '172px' : '134px'),
            position: 'sticky',
            top: 0,
            zIndex: 1000,
            marginBottom: '14px'
          }}
          disablePadding
        >
          {includeHeader && (
            <AddContactDrawerHeader
              title={t('addContactDrawer.title')}
              subtitle={t('addContactDrawer.createContactSubtitle')}
              onClose={() => toggleDrawer(false)}
              onSave={onSave}
              onSaveAndAddAnother={onSaveAndAddAnother}
            />
          )}
          {includeSubHeader && (
            <AddContactDrawerSubHeader
              uploadResumeTitle={t('addContactDrawer.uploadResumeToCreateContact')}
              linkedinURLTitle={t('addContactDrawer.LinkedinURL')}
              getProfileData={t('addContactDrawer.getProfileData')}
              expandAll={t('addContactDrawer.expandAll')}
              collapseAll={t('addContactDrawer.collapseAll')}
              onExpandAll={expandAll}
              onCollapseAll={collapseAll}
              onGetProfileData={addFromLinkedin}
              allExpanded={Object.values(expand).every(value => value === true)}
              allCollapsed={Object.values(expand).every(value => value === false)}
              onOpenCreateContactFromResume={() => setOpenCreateContactFromResume(true)}
              onAddFromLinkedin={() => {}}
              duplicateContact={duplicateContact}
              setDuplicateContact={() =>
                setDuplicateContact({
                  isDuplicate: false,
                  string: null,
                  data: []
                })
              }
              linkedinURL={linkedinURL}
              setLinkedinURL={setLinkedinURL}
            />
          )}
          {CustomHeader && (
            <CustomHeader
              uploadResumeTitle={t('addContactDrawer.uploadResumeToCreateContact')}
              linkedinURLTitle={t('addContactDrawer.LinkedinURL')}
              getProfileData={t('addContactDrawer.getProfileData')}
              expandAll={t('addContactDrawer.expandAll')}
              collapseAll={t('addContactDrawer.collapseAll')}
              onExpandAll={expandAll}
              onCollapseAll={collapseAll}
              onGetProfileData={addFromLinkedin}
              allExpanded={Object.values(expand).every(value => value === true)}
              allCollapsed={Object.values(expand).every(value => value === false)}
              onOpenCreateContactFromResume={() => setOpenCreateContactFromResume(true)}
              onAddFromLinkedin={() => {}}
              onSave={onSave}
              globalLoading={globalLoading}
            />
          )}
        </ListItem>
        <ListItem
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '16px',
            borderRadius: '24px'
          }}
        >
          {sections.map((section, index) => (
            <AddContactDrawerAccordion
              key={index}
              title={section.title}
              expand={section.expand}
              setExpand={section.setExpand}
              addTitle={section.addTitle}
              onClickAdd={section.onClickAdd}
              // loading={loadingDropdownData}
              visible={section.visible}
              globalLoading={globalLoading}
            >
              {section.children(props)}
            </AddContactDrawerAccordion>
          ))}
        </ListItem>
      </List>
      {openAlertDialog && (
        <AlertDialog
          open={openAlertDialog}
          handleClose={() => setOpenAlertDialog(false)}
          handleSubmit={saveAPI}
          title={t('addContactDrawer.unsavedChanges')}
          description={t('addContactDrawer.unsavedChangesDescription')}
          cancelText={t('addContactDrawer.no')}
          submitText={t('addContactDrawer.yes')}
        />
      )}
      {openCreateContactFromResume && <ResumeParser isPopupOpen={openCreateContactFromResume} handleClose={handleResumeParseClose} />}
    </Box>
  );
}

export default ContactDrawerUI;
ContactDrawerUI.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  setOpenDrawer: PropTypes.func.isRequired,
  recordTypeDropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  projectList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  projectStageList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  countryList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  stateList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  companyNameList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  levelOfEducationList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  certificationTypeList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  phoneTypeList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  emailTypeList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  addressTypeList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  languageList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  levelOfProficiencyList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  jobFunctionList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  industryList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  nationalityList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getDropdownData: PropTypes.func.isRequired,
  RelationTypeList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loadingDropdownData: PropTypes.bool.isRequired,
  saveContact: PropTypes.func.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  includeHeader: PropTypes.bool.isRequired,
  includeSubHeader: PropTypes.bool.isRequired,
  CustomHeader: PropTypes.func.isRequired,
  customHeaderHeight: PropTypes.string.isRequired,
  sectionVisibility: PropTypes.object,
  customSectionPrefix: PropTypes.arrayOf(PropTypes.shape({})),
  type: PropTypes.string, // create or edit
  contactData: PropTypes.object,
  duplicateContact: PropTypes.object,
  setDuplicateContact: PropTypes.func,
  defaultValues: PropTypes.object,
  globalLoading: PropTypes.bool,
  callback: PropTypes.func
};
