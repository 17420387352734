import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPickLists } from '../../../../actions';
import CustomDropdown from '../../CustomDropdown';

const CompanyAddressTypeSelection = props => {
  const { value, name = '', onChange = () => {}, disabled = false, selectedList, isDrawer = false, label = '', setAddressTypeList = () => {}, ...rest } = props;
  const [options, setOptions] = useState([]);
  const companyAddressTypeOption = useSelector(state => state.commonReducer.companyAddressType);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!companyAddressTypeOption) {
      dispatch(fetchPickLists('COMPANY_ADDRESS_TYPE', 'companyAddressType'));
    } else {
      if (companyAddressTypeOption) {
        setOptions(companyAddressTypeOption);
      } else {
        setOptions([]);
      }
    }
  }, [companyAddressTypeOption, dispatch, selectedList]);

  return (
    <div>
      <CustomDropdown {...rest} value={value} options={options} name={name} onChange={onChange} disabled={disabled} label={label} isDrawer={isDrawer} />
    </div>
  );
};
CompanyAddressTypeSelection.propTypes = {
  value: PropTypes.object,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  isDrawer: PropTypes.bool,
  onChange: PropTypes.func,
  setAddressTypeList: PropTypes.func,
  selectedList: PropTypes.array,
  label: PropTypes.string
};

export default CompanyAddressTypeSelection;
