import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import {
  contactEducationApi,
  fetchTagByModule,
  ignContactDataApi,
  ignUsersApi,
  LocationSetupApi,
  picklistDropDownApis,
  picklistsDataApis,
  ProjectDataApi,
  ProjectStagesDataApi
} from '../../../services/ApiService';
import { DROP_DOWN_PICKLIST, GET } from '../../../services/constantService';
import { commonActions } from '../../../store/commonSlice';
import { CONSTANTS_SHOW_OFF_LIMIT } from '../../common/ShowOffLimits/constant';

export const getFormattedData = (selectedTabId, item, sort = null) => {
  if (selectedTabId === 'ign-contacts') {
    const data = item?._source;
    const name = (data?.first_name || '') + ' ' + (data?.last_name || '');
    const jobTitle = data?.current_job_title;
    let linkedInUrl = data?.linkedin_url;
    linkedInUrl = linkedInUrl?.startsWith('http') ? linkedInUrl : linkedInUrl ? `https://${linkedInUrl}` : linkedInUrl;
    const location = data?.contact_address?.[0]?.metropolitan_area;
    const companyName = data?.contact_company?.name;
    const email = data?.contact_emails?.[0]?.email;
    const source = data?.source;
    const contactId = data?.id;
    const directOfflimit = data?.direct_off_limit;
    const tags =
      sort === null
        ? data?.contact_tags?.map(tag => ({ name: tag?.ign_tags?.name, moduleId: contactId })) || []
        : _.orderBy(data?.contact_tags?.map(tag => ({ name: tag?.ign_tags?.name, moduleId: contactId })) || [], [str => str], [sort === 'asc' ? 'desc' : 'asc']);

    if (tags?.length === 0) tags?.push({ moduleId: contactId });
    const offlimits = [];
    if (directOfflimit) {
      offlimits.push({ ...directOfflimit, type: CONSTANTS_SHOW_OFF_LIMIT.DIRECT });
    }

    return {
      id: item?._id,
      contact_id: contactId,
      name: {
        name,
        linkedin_url: linkedInUrl,
        contactId
      },
      current_job_title: jobTitle,
      company: companyName,
      email,
      tags,
      location,
      source,
      offlimits: {
        offlimits,
        contactId
      }
    };
  }

  if (selectedTabId === 'ign-companies') {
    const data = item?._source;
    const industryName = data?.company_industries?.[0]?.industries?.name;
    const location = data?.company_address?.[0]?.metropolitan_area;

    const companyName = data?.name;
    const companyId = data?.id;
    const capitalStructure = data?.capital_struct_trans_value?.short_desc;
    const companySize = data?.employee_range_from !== undefined && data?.employee_range_from !== null ? `${data?.employee_range_from}-${data?.employee_range_to}` : '--';
    const directOfflimit = data?.company_off_limit;
    const offlimits = [];
    if (directOfflimit) {
      offlimits.push({ ...directOfflimit, type: CONSTANTS_SHOW_OFF_LIMIT.DIRECT });
    }
    const tags =
      sort === null
        ? data?.company_tags?.map(tag => ({ name: tag?.ign_tag?.name, moduleId: companyId })) || []
        : _.orderBy(data?.company_tags?.map(tag => ({ name: tag?.ign_tag?.name, moduleId: companyId })) || [], [str => str], [sort === 'asc' ? 'desc' : 'asc']);

    if (tags?.length === 0) tags?.push({ moduleId: companyId });
    return {
      id: item?._id,
      company_id: companyId,
      name: {
        name: companyName,
        companyId
      },
      offlimits: {
        offlimits,
        companyId
      },
      industry_name: industryName,
      location,
      capital_structure: capitalStructure,
      company_size: companySize,
      tags
    };
  }

  if (selectedTabId === 'ign-projects') {
    const data = item?._source;
    const stage = data?.stage;
    const projectName = `${data?.job_number} - ${data?.job_title}`;
    const industries = data?.industries;
    const location = data?.location;
    const companyName = data?.ign_companies?.name;
    const projectNo = data?.job_number;
    const projectId = data?.id;
    const companyId = data?.company_id;

    const tags =
      sort === null
        ? data?.project_tags?.map(tag => ({ name: tag?.tag?.name, moduleId: projectId })) || []
        : _.orderBy(data?.project_tags?.map(tag => ({ name: tag?.tag?.name, moduleId: projectId })) || [], [str => str], [sort === 'asc' ? 'desc' : 'asc']);

    if (tags?.length === 0) tags?.push({ moduleId: projectId });
    return {
      id: item?._id,
      company_id: {
        companyId,
        projectId
      },
      project_id: projectId,
      name: {
        name: projectName,
        projectId
      },
      company_name: companyName,
      project_no: projectNo,
      stage,
      location,
      industries,
      tags
    };
  }

  if (selectedTabId === 'ign-contact-notes-all') {
    return {
      id: item?._id,
      item,
      tags: []
    };
  }
};

export const getTotalCount = (selectedTabId, data) => {
  const list = data?.find(item => item?.model === selectedTabId);
  return list?.data?.hits?.total?.value;
};

export const getSearchResults = async (tab, search, selectedTabId, commonSelector, dispatch) => {
  let subRoute = '';

  if (tab?.type === 'INDUSTRY') {
    subRoute = DROP_DOWN_PICKLIST.INDUSTRIES_PICKLIST;
  }
  if (tab?.type === 'COMPANY') {
    subRoute = DROP_DOWN_PICKLIST.COMPANIES_PICKLIST;
    const res = await picklistDropDownApis(GET, subRoute);
    if (res?.status === 200) {
      return res?.data?.data?.filter(item => item?.name?.toLowerCase()?.includes(search?.toLowerCase())).map(item => getFormattedSearchResponse(item, 'id', 'name'));
    }
  }
  if (tab?.type === 'CONTACT') {
    const res = await ignContactDataApi(GET, null, null, `/query?searchValue=${search}`);
    if (res?.status === 200) {
      return res?.data?.data?.map(item => ({ id: item?.id, value: item?.first_name + ' ' + item?.last_name }));
    }
  }

  if (tab?.type === 'PROJECT') {
    const res = await ProjectDataApi(GET, null, null, `/query?searchValue=${search}`);
    if (res?.status === 200) {
      return res?.data?.data?.map(item => ({ id: item?.id, value: `${item?.job_title} (${item?.ign_companies?.name})` }));
    }
  }

  if (tab?.type === 'CONTACT_SOURCE' || tab?.type === 'COMPANY_CAPITAL_STRUCTURE' || tab?.type === 'COMPANY_RECORD_STATUS') {
    let type = tab?.type;
    if (tab?.type === 'COMPANY_RECORD_STATUS') {
      type = 'COMPANY_STATUS';
    }
    const res = await picklistsDataApis(GET, type);
    if (res?.status === 200) {
      return res?.data?.filter(item => item?.short_desc?.toLowerCase()?.includes(search?.toLowerCase()))?.map(item => getFormattedSearchResponse(item, 'id', 'short_desc'));
    }
    return [];
  }

  try {
    if (tab?.type === 'LOCATION') {
      const res = await LocationSetupApi(GET, search);
      return res?.data?.predictions.map(item => getFormattedSearchResponse(item, 'place_id', 'description'));
    }

    if (tab?.type === 'PROJECT_STAGE') {
      const storeKeyName = tab?.type;

      if (commonSelector?.[storeKeyName]?.length) {
        return commonSelector?.[storeKeyName]?.filter(i => i?.value?.toLowerCase()?.includes(search?.toLowerCase()));
      }
      const res = await ProjectStagesDataApi(GET);
      const data = res?.data?.map(item => getFormattedSearchResponse(item, 'id', 'name'));
      dispatch(commonActions.updatePicklistData({ key: storeKeyName, data }));
      return data;
    }

    if (tab?.type === 'CONTACT_TAGS' || tab?.type === 'PROJECT_TAGS' || tab?.type === 'COMPANY_TAGS') {
      const module = selectedTabId?.replace('ign-', '');
      const moduleStoreKeyName = `${module}Tag`;

      if (commonSelector?.[moduleStoreKeyName]?.length) {
        return commonSelector?.[moduleStoreKeyName]
          .map(item => getFormattedSearchResponse({ ...item, name: item?.value || item?.name }, 'id', 'name'))
          .filter(i => i?.value?.toLowerCase()?.includes(search?.toLowerCase()));
      }
      const res = await fetchTagByModule(GET, `?module=${module}&name=${search}`);
      if (res?.status === 200) {
        const data = res?.data?.map(item => getFormattedSearchResponse(item, 'id', 'name'));
        dispatch(commonActions.updatePicklistData({ key: moduleStoreKeyName, data }));
        return data;
      }
      return [];
    }

    if (tab?.type === 'USERS') {
      const res = await ignUsersApi(GET, `getAll?search=${search}`);
      return res?.data?.map(item => getFormattedSearchResponse(item, 'id', 'name'));
    }

    if (tab?.type === 'EDUCATION') {
      const seenSchoolNames = new Set();

      const searchText = `%${search}%`;
      const res = await contactEducationApi(
        GET,
        '',
        {},
        {
          where: JSON.stringify({
            $or: [{ school_name: { $iLike: searchText } }]
          })
        }
      );
      return res?.data
        ?.filter(i => {
          if (seenSchoolNames.has(i.school_name) || !i.school_name) {
            return false;
          }
          seenSchoolNames.add(i.school_name);
          return true;
        })
        ?.map(item => getFormattedSearchResponse(item, 'id', 'school_name'));
    }

    const res = await picklistDropDownApis(GET, subRoute);

    if (res?.status === 200) {
      return res?.data?.filter(item => item?.name?.toLowerCase()?.includes(search?.toLowerCase())).map(item => getFormattedSearchResponse(item, 'id', 'name'));
    }
    return [];
  } catch (error) {
    return [];
  }

  function getFormattedSearchResponse(item, id, value) {
    return { id: item?.[id], value: item?.[value] };
  }
};

export const getNestedValue = (obj, key) => {
  const keys = key.split('.');
  let result = obj;

  for (let i = 0; i < keys.length; i++) {
    if (!result) {
      return undefined;
    }

    if (Array.isArray(result)) {
      result = result?.[0];
    }

    result = result?.[keys[i]];
  }

  return result;
};

export const getLocalSearchResults = (tab, search, selectedTabData, dispatch, selectedTabId) => {
  const seenResults = new Set();
  const module = selectedTabId?.replace('ign-', '');
  const storeKeyName = module + tab?.type;

  const attributeKey = tab?.search_field?.[0];

  const data = selectedTabData?.data?.hits?.hits
    ?.filter(i => {
      const value = getNestedValue(i._source, attributeKey);
      if (seenResults.has(value) || !value) {
        return false;
      }
      seenResults.add(value);
      return true;
    })
    .map(item => getFormattedSearchResponse(item?._source, 'id', attributeKey))
    ?.filter(i => i?.value?.toLowerCase()?.includes(search?.toLowerCase()));

  dispatch(commonActions.updatePicklistData({ key: storeKeyName, data }));

  return data;

  function getFormattedSearchResponse(item, id, attributeKey) {
    const value = getNestedValue(item, attributeKey);
    return { id: item?.[id], value };
  }
};

export function htmlToPlainText(html) {
  const tempElement = document.createElement('div');

  tempElement.innerHTML = html;

  return tempElement.innerText || tempElement.textContent;
}

export const useStyles = makeStyles({
  text: {
    overflow: 'hidden',
    width: '165px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontFamily: 'inherit'
  }
});

export const renderHtmlContent = (text, { sx, className }) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: text }}
      className={className}
      style={{
        ...sx
      }}
    />
  );
};

export function getDateRange(type, customStart = null, customEnd = null) {
  let startDate, endDate;

  switch (type) {
    case 'today':
      startDate = moment().utc().startOf('day');
      endDate = moment().utc().endOf('day');
      break;
    case 'yesterday':
      startDate = moment().utc().subtract(1, 'days').startOf('day');
      endDate = moment().utc().subtract(1, 'days').endOf('day');
      break;
    case 'thisWeek':
      startDate = moment().utc().startOf('week');
      endDate = moment().utc().endOf('week');
      break;
    case 'thisMonth':
      startDate = moment().utc().startOf('month');
      endDate = moment().utc().endOf('month');
      break;
    case 'custom':
      startDate = moment(customStart).utc().startOf('day');
      endDate = moment(customEnd).utc().endOf('day');
      break;
    default:
      throw new Error('Invalid range type');
  }

  return [startDate.toISOString(), endDate.toISOString()];
}

export const getModuleName = selectedTabId => {
  const module = selectedTabId?.replace('ign-', '');

  switch (module) {
    case 'contacts':
      return 'Contact';

    case 'projects':
      return 'Project';

    case 'companies':
      return 'Company';

    case 'contact-notes-all':
      return 'Activity';

    default:
      return 'All';
  }
};

export const findMatchingValue = (obj, filterValue) => {
  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === 'object' && value !== null) {
      if (Array.isArray(value)) {
        const found = value.find(item => findMatchingValue(item));
        if (found) return true;
      } else {
        if (findMatchingValue(value)) return true;
      }
    } else if (String(value).toLowerCase().includes(filterValue)) {
      return true;
    }
  }
  return false;
};
